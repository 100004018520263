export { default as MakeIconOrange } from './makeIconOrange.svg';
export { default as CalendarIconOrange } from './calendarIconOrange.svg';
export { default as KMIconOrange } from './kMIconOrange.svg';
export { default as LocationIconOrange } from './locationIconOrange.svg';
export { default as ModelIconOrange } from './modelIconOrange.svg';
export { default as ContactIconOrange } from './contactIconOrange.svg';
export { default as otherInfoIcon } from './info-circle.svg';
export { default as otherInfoIconOrange } from './info-circle-orange.svg';

export { default as SellCarBackIcon } from './sellCarBackIcon.svg';
export { default as FAQsIcon } from './FAQsIcon.svg';
export { default as ArrowDownGrey } from './arrowDownGrey.svg';
export { default as AltertTriangle } from './alertTriangle.svg';
export { default as ArrowDownSmall } from './arrowDownSmall.svg';
export { default as TickIcon } from './tickIcon.svg';
export { default as WareHouseInspection } from './wareHouseInspection.svg';
export { default as Path1ThankYou } from './path1ThankYou.svg';
export { default as Path2ThankYou } from './path2ThankYou.svg';
export { default as Path1ThankYouVisitWarehouse } from './path1ThankYouVisitWarehouse.svg';
export { default as Path2ThankYouVisitWarehouse } from './path2ThankYouVisitWarehouse.svg';
export { default as SellContactImg } from './sellContactImg.svg';
export { default as LockIcon } from './lockIcon.svg';

export { default as AdminFeesIcon } from './adminFeesIcon.png';
export { default as ApprovalDuration } from './check.svg';
export { default as DocumentsNeeded } from './documentsNeeded.svg';
export { default as UpToYears } from './upToYears.svg';
export { default as CarSellBan } from './carSellBan.svg';
export { default as FromDownPayment } from './fromDownPayment.svg';
export { default as LocationNotAllowed } from './LocationNotAllowed.svg';
export { default as InfoCircleRed } from './infoCircleRed.svg';
export { default as LensGreenIcon } from './lensGreenIcon.svg';
export { default as LensOrangeIcon } from './lensOrangeIcon.svg';
export { default as ShowroomImageSell } from './headofficeshowroom.jpeg';
export { default as WebsiteHeroBg } from './wb-bg-img.jpg';
export { default as MapBlue } from './mapBlue.svg';
export { default as SylndrMapLocation } from './sylndrMapLocation.jpg';
export { default as WhiteMarker } from './whiteMarker.svg';
export { default as GrayArrow } from './grayArrow.svg';
export { default as PinLocationBlue } from './pinLocationBlue.svg';
export { default as PinLocationGray } from './pinLocationGray.svg';
export { default as DirectionArrow } from './direction.svg';
export { default as CarInspection } from './carInspection.svg';
export { default as GetOurOffer } from './GetOurOffer.svg';
export { default as InstantPayment } from './instantPaymentAndOwnershipTransfer.svg';
export { default as hubImage } from './hubImage.png';
export { default as hubImage1 } from './hubImage(1).png';
export { default as hubImage2 } from './hubImage(2).png';
export { default as hubImage3 } from './hubImage(3).png';
export { default as hubImage4 } from './hubImage(4).png';
export { default as hubImage5 } from './hubImage(5).png';
export { default as hubImage6 } from './hubImage(6).png';
export { default as specialInspection } from './specialInspection.svg';
export { default as sellPageBannerEnglish } from './sellPageBannerEnglish.png';
