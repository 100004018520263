'use client';
import {
    setBaseFormData,
    setFormDataItems,
} from '@/redux/reducers/acquisition/sell-form-date.reducer';
import { useAppDispatch, useAppSelector } from '@/redux/redux-hook';
import {
    ISellCarForm,
    ISellCarFormDataItem,
    TFormItemKey,
    TSellFormDataItems,
} from '@/types';

const useSellFormData = () => {
    const { baseFormData, formDataItems } = useAppSelector(
        state => state.sellFomData
    );
    // const [formDataItems, setFormDataItems] = useState<ISellCarFormDataItem[]>([]);

    const dispatch = useAppDispatch();

    // build form data item
    const formDataItemBuilder = (
        item: TSellFormDataItems,
        key: TFormItemKey
    ): ISellCarFormDataItem => {
        const formDataItem: ISellCarFormDataItem = {
            key: key,
            dataItem: item,
        };
        return formDataItem;
    };
    // create form objects
    const createFormObjects = (
        formObjects: ISellCarForm
    ): ISellCarFormDataItem[] => {
        const keys = Object.keys(formObjects) as [keyof ISellCarForm];
        const formDataItems = keys.map(key => {
            return formDataItemBuilder(formObjects[key], key);
        });
        return formDataItems;
    };

    // update form object
    const updateFormDataItems = (
        itemKey: TFormItemKey,
        itemValue: TSellFormDataItems
    ) => {
        const newFormData: ISellCarFormDataItem[] = formDataItems.map(item => {
            if (item.key === itemKey) {
                const updatedItem = { ...item, dataItem: itemValue };
                return updatedItem;
            }
            return item;
        });
        return newFormData;
    };
    // clear form object
    const clearFormObjects = () => {
        const newFormItems = formDataItems.map(item => {
            return formDataItemBuilder(baseFormData[item.key], item.key);
        });
        return newFormItems;
    };

    // clear form object if prevoius step is modified
    // const clearFormObjectsAfterCertainStep = (itemKey: TFormItemKey) => {
    //     const itemIndex = formDataItems.findIndex(item => item.key === itemKey);
    //     const newFormItems = formDataItems.map((item, index) => {
    //         if (itemIndex <= index) return item;
    //         else return formDataItemBuilder(baseFormData[item.key], item.key);
    //     });
    //     return newFormItems;
    // };
    const clearFormObjectsAfterCertainStepChainer = (
        itemKey: TFormItemKey,
        formData: ISellCarFormDataItem[]
    ) => {
        const itemIndex = formData.findIndex(item => item.key === itemKey);
        const newFormItems = formData.map((item, index) => {
            if (index <= itemIndex) return item;
            else return formDataItemBuilder(baseFormData[item.key], item.key);
        });
        return newFormItems;
    };

    const intializeFormData = (
        formData: ISellCarForm,
        forceInitialize = false
    ) => {
        if (formDataItems.length === 0 || forceInitialize) {
            const dataItems = createFormObjects(formData);
            dispatch(setFormDataItems(dataItems));
            //   setFormDataItems(dataItems);
            dispatch(setBaseFormData(formData));
        }
    };
    const updateFormDataItemsReducer = (
        itemKey: TFormItemKey,
        itemValue: TSellFormDataItems,
        doNotClearSubsequentData?: boolean
    ) => {
        //update form data with selected value
        const formData = updateFormDataItems(itemKey, itemValue);

        if (doNotClearSubsequentData) {
            dispatch(setFormDataItems(formData));
            return;
        }

        // clear subsequent steps
        const updatedFormData = clearFormObjectsAfterCertainStepChainer(
            itemKey,
            formData
        );

        dispatch(setFormDataItems(updatedFormData));
        // setFormDataItems(formData);
    };

    const resetFormDataObjects = () => {
        const newItems = clearFormObjects();
        dispatch(setFormDataItems(newItems));
    };

    // get form item value
    const getFormItemValue = (itemKey: TFormItemKey) => {
        const itemIndex = formDataItems.findIndex(item => item.key === itemKey);
        if (!formDataItems[itemIndex]?.dataItem) {
            return baseFormData[itemKey];
        }
        return formDataItems[itemIndex]?.dataItem;
    };
    // get form objects
    const getFormDataItems = () => {
        return formDataItems;
    };

    return {
        intializeFormData,
        updateFormDataItemsReducer,
        resetFormDataObjects,
        getFormItemValue,
        getFormDataItems,
        clearFormObjects,
    };
};
export default useSellFormData;
