'use client';
import { ICoordinate } from '@/types';
import { ISmallArea } from './acquisition/use-sell-car.hook';

function area(polygon: ICoordinate[]) {
    let total = 0;
    for (let i = 0; i < polygon.length; i++) {
        const addX = polygon[i].lat;
        const addY = polygon[i === polygon.length - 1 ? 0 : i + 1].lng;
        const subX = polygon[i === polygon.length - 1 ? 0 : i + 1].lat;
        const subY = polygon[i].lng;
        total += addX * addY * 0.5 - subX * subY * 0.5;
    }
    return Math.abs(total);
}
function compareAreas(a: ISmallArea, b: ISmallArea) {
    if (area(a.polygon) > area(b.polygon)) return -1;
    else if (area(b.polygon) > area(a.polygon)) return 1;
    return 0;
}

function findPolygon(
    areas: ISmallArea[],
    coordinate: ICoordinate
): ISmallArea | null {
    for (const area of areas) {
        if (
            area.polygon.length &&
            isPointInPolygon(coordinate, area.polygon) &&
            area.serviceable
        ) {
            return area;
        }
    }

    const matchingAreas = areas
        .filter(
            area =>
                area.polygon.length &&
                isPointInPolygon(coordinate, area.polygon)
        )
        .sort(compareAreas);

    const matched = matchingAreas.pop() || null;

    return matched;
}

function isPointInPolygon(point: ICoordinate, polygon: ICoordinate[]): boolean {
    const googlePolygon = new google.maps.Polygon({
        paths: polygon.map(coord => ({ lat: coord.lat, lng: coord.lng })),
    });

    const containsLocation = google?.maps?.geometry?.poly?.containsLocation(
        new google.maps.LatLng(point.lat, point.lng),
        googlePolygon
    );
    return containsLocation;
}

export function findPointInPolygon(
    isLoaded: boolean,
    polygons: ISmallArea[],
    coordinate: ICoordinate
): ISmallArea | null {
    if (!isLoaded) return null;
    const polygon = findPolygon(polygons, coordinate);
    return polygon;
}
