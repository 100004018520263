import { useGrowthBook } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';

export function useAsyncIsFlagOn<
    AppFeatures extends Record<string, any> = Record<string, any>
>(id: string & keyof AppFeatures): boolean | string {
    const growthbook = useGrowthBook<AppFeatures>();
    const [pendingState, setPendingState] = useState<string | boolean>(
        'pending'
    );

    useEffect(() => {
        const pendingTimeout = setTimeout(() => {
            if (!growthbook) {
                setPendingState(false);
            }
        }, 3000);

        return () => {
            clearTimeout(pendingTimeout);
        };
    }, []);

    return growthbook ? growthbook.isOn(id) : pendingState;
}
