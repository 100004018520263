import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStepper_SELL_Reducer, IStepperItem_SELL_NEW, TStepperPath } from '@/types';

const initialState: IStepper_SELL_Reducer = {
  stepper: [],
  currentStep: {
    allowed: true,
    activated: true,
    manuallyDisabled: false,
    icon: '',
    label: 'make',
    step: 1,
    pathOneOnly: false,
    value: '',
    valueAr: '',
    inShoppingList: true,
    iconValue: '',
    backgroundColor: '',
    mainIcon: '',
    showAlert: false,
  },
  path: 'path1',
};

export const sellFormStepper = createSlice({
  name: 'sellFormStepper',
  initialState,
  reducers: {
    setStepper: (state, action: PayloadAction<IStepperItem_SELL_NEW[]>) => {
      state.stepper = action.payload;
    },
    setCurrentlyActiveStep: (state, action: PayloadAction<IStepperItem_SELL_NEW>) => {
      state.currentStep = action.payload;
    },

    setPath: (state, action: PayloadAction<TStepperPath>) => {
      state.path = action.payload;
    },
  },
});

export const { setStepper, setCurrentlyActiveStep, setPath } = sellFormStepper.actions;

export default sellFormStepper.reducer;
