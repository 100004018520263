'use client';
import {
    IStepperIntializer,
    IStepperItem_SELL_NEW,
    IStepValues,
    StepperStep,
    TStepperPath,
} from '@/types';
import { useAppDispatch, useAppSelector } from '@/redux/redux-hook';
import {
    setCurrentlyActiveStep,
    setPath,
    setStepper,
} from '@/redux/reducers/acquisition/sell-form-stepper.reducer';
import { usePathname } from 'next/navigation';

const useSellStepper = () => {
    const {
        stepper,
        currentStep: currentlyActiveStep,
        path,
    } = useAppSelector(state => state.sellFormStepper);
    const dispatch = useAppDispatch();
    const pathName = usePathname();

    const stepperStepBuilder = ({
        label,
        pathOneOnly,
        stepNumber,
        icon,
        activate,
        manuallyDisabled,
        allowed,
    }: {
        label: StepperStep;
        pathOneOnly: boolean;
        stepNumber: number;
        icon: string;
        activate?: boolean;
        manuallyDisabled?: boolean;
        allowed?: boolean;
    }) => {
        const activated = activate ? activate : stepNumber === 1;
        const step: IStepperItem_SELL_NEW = {
            allowed: allowed || true,
            activated: activated,
            manuallyDisabled: manuallyDisabled || false,
            icon: icon,
            label: label,
            step: stepNumber,
            pathOneOnly: pathOneOnly,
            value: '',
            valueAr: '',
            inShoppingList: true,
            iconValue: '',
            backgroundColor: '',
            mainIcon: icon,
            showAlert: false,
        };
        return step;
    };

    const getStepNumber = (step: StepperStep): number => {
        return stepper.findIndex(s => s.label === step);
    };

    // take steps and handle order of the steps
    const createStepperSteps = (stepperSteps: IStepperIntializer[]) => {
        const steps: IStepperItem_SELL_NEW[] = stepperSteps.map(
            (step, index) => {
                return stepperStepBuilder({
                    label: step.label,
                    pathOneOnly: step.pathOneOnly,
                    stepNumber: index + 1,
                    icon: step.icon,
                    manuallyDisabled: step?.manuallyDisabled,
                    allowed: step?.allowed,
                });
            }
        );
        return steps;
    };

    // fill step values
    const fillStep = (
        step: StepperStep,
        stepValues: IStepValues
    ): IStepperItem_SELL_NEW[] => {
        const newStepper = stepper.map((stepperStep, index) => {
            if (stepperStep.label === step) {
                const newStep: IStepperItem_SELL_NEW = {
                    ...stepper[index],
                    ...stepValues,
                };
                return newStep;
            }
            return stepperStep;
        });
        return newStepper;
    };

    // adjust which steps are allowed depending on the selected data
    const adjustAllowedStep = (
        step: StepperStep,
        chainedStepper: IStepperItem_SELL_NEW[],
        path: TStepperPath
    ): IStepperItem_SELL_NEW[] => {
        // if step is pathOneOnly and we entered path2 before reaching it set allow false
        // if step is pathOneOnly and we entered path2 beacause of it allow the step
        // in summary step should not be allowed if pathOneOnly is true and we entred path2 before reaching it
        const stepperIndex = getStepNumber(step);

        const newStepper = chainedStepper.map((stepperStep, index) => {
            if (stepperStep.manuallyDisabled)
                return { ...stepperStep, allowed: false, activated: false };
            if (index >= stepperIndex) {
                let allowStep = stepperStep.pathOneOnly
                    ? path === 'path1'
                    : true;
                if (stepperStep.label === 'region') {
                    if (chainedStepper[index - 1].inShoppingList) {
                        allowStep = true;
                    }
                }
                const newStep: IStepperItem_SELL_NEW = {
                    ...stepperStep,
                    allowed: allowStep,
                };

                return newStep;
            }

            return stepperStep;
        });
        return newStepper;
    };

    // reset stepper if one of the previous values is changed
    const resetStepperValuesAfterCertainStepValueChange = (
        step: StepperStep
    ) => {
        const stepperIndex = getStepNumber(step);
        const newStepper = stepper.map((stp, index) => {
            if (stp.manuallyDisabled)
                return { ...stp, allowed: false, activated: false };
            if (index <= stepperIndex) return stp;
            else
                return stepperStepBuilder({
                    label: stp.label,
                    pathOneOnly: stp.pathOneOnly,
                    stepNumber: stp.step,
                    icon: stp.icon,
                });
        });
        return newStepper;
    };

    // reset stepper if one of the previous values is changed chained
    const resetStepperValuesAfterCertainStepValueChangeChainer = (
        step: StepperStep,
        chainedStepper: IStepperItem_SELL_NEW[]
    ) => {
        const stepperIndex = getStepNumber(step);
        const newStepper = chainedStepper.map((stp, index) => {
            if (stp.manuallyDisabled)
                return { ...stp, allowed: false, activated: false };
            if (index <= stepperIndex) return stp;
            else
                return stepperStepBuilder({
                    label: stp.label,
                    pathOneOnly: stp.pathOneOnly,
                    stepNumber: stp.step,
                    icon: stp.icon,
                });
        });
        return newStepper;
    };

    // get previous step
    const getPreviousStep = (step: StepperStep) => {
        const stepperIndex = getStepNumber(step);
        if (stepperIndex === 0) return;
        for (let i = stepperIndex - 1; i >= 0; i -= 1) {
            if (stepper[i].activated && stepper[i].allowed) return stepper[i];
        }
    };

    // go to a specific step
    const getSpecificStep = (step: StepperStep) => {
        const stepperIndex = getStepNumber(step);
        if (!stepper[stepperIndex].activated || !stepper[stepperIndex].allowed)
            return;
        return stepper[stepperIndex];
    };

    // get the last active step
    const getLastActiveStep = () => {
        for (let i = stepper.length - 1; i >= 0; i -= 1) {
            if (stepper[i].activated && stepper[i].allowed) return stepper[i];
        }
    };

    const getNextAllowedStep = (chainedStepper: IStepperItem_SELL_NEW[]) => {
        for (let i = chainedStepper.length - 1; i >= 0; i -= 1) {
            if (chainedStepper[i].activated && chainedStepper[i].allowed) {
                for (let k = i + 1; k < chainedStepper.length; k += 1) {
                    if (chainedStepper[k].allowed) return chainedStepper[k];
                }
            }
        }
    };

    // change url based on current step
    const changeUrlBasedOnCurrentStep = (targetStep: StepperStep) => {
        const location = pathName;
        if (
            targetStep !== pathName &&
            location.includes('sell-form') &&
            !location.includes('thankyou')
        ) {
            // createQueryString('step', targetStep);
            window.location.hash = targetStep;
            // router.push(`sell-form?step=${targetStep}`);
        }
    };

    // get stepper
    const getStepper = () => {
        return stepper;
    };
    // get step data
    const getStepData = (step: StepperStep) => {
        const index = getStepNumber(step);
        return stepper[index];
    };

    // get path
    const getPath = () => {
        return path;
    };

    // activate next step when current step is filled chainer
    const activateNextStepChainer = (
        chainedStepper: IStepperItem_SELL_NEW[]
    ) => {
        const nextAllowedStep = getNextAllowedStep(chainedStepper);

        if (!nextAllowedStep) return;

        const newStepper = chainedStepper.map(stepperStep => {
            if (stepperStep.manuallyDisabled)
                return { ...stepperStep, allowed: false, activated: false };
            if (stepperStep.label === nextAllowedStep.label) {
                const newStep: IStepperItem_SELL_NEW = {
                    ...stepperStep,
                    activated: true,
                };
                return newStep;
            }
            return stepperStep;
        });
        const activeStep: IStepperItem_SELL_NEW = {
            ...nextAllowedStep,
            activated: true,
        };
        return { newStepper, activeStep };
    };

    // adjust step number based on allowed steps
    const adjustStepNumberBasedOnAllowedValue = (
        chainedStepper: IStepperItem_SELL_NEW[]
    ): IStepperItem_SELL_NEW[] => {
        let stepNumber = 1;
        const modifiedStepper = chainedStepper.map(stp => {
            if (stp.allowed) {
                const newStep = { ...stp, step: stepNumber };
                stepNumber += 1;
                return newStep;
            }
            return { ...stp, step: -1 };
        });
        return modifiedStepper;
    };

    const intializeStepperSteps = (stepperSteps: IStepperIntializer[]) => {
        const stepps = createStepperSteps(stepperSteps);
        dispatch(setStepper(stepps));
        dispatch(setCurrentlyActiveStep(stepps[0]));
    };

    // reset all stepper steps
    const resetStepper = () => {
        const steps: IStepperItem_SELL_NEW[] = stepper.map((step, index) => {
            return stepperStepBuilder({
                label: step.label,
                pathOneOnly: step.pathOneOnly,
                stepNumber: index + 1,
                icon: step.icon,
                manuallyDisabled: step.manuallyDisabled,
            });
        });
        dispatch(setStepper(steps));
        dispatch(setCurrentlyActiveStep(steps[0]));
    };

    // reset steps after a latter step is changed
    const resetStepperAfterPreviousStepChange = (step: StepperStep) => {
        const newSteps = resetStepperValuesAfterCertainStepValueChange(step);
        dispatch(setStepper(newSteps));
    };

    const updateCurrentlyActiveStep = (step: IStepperItem_SELL_NEW) => {
        dispatch(setCurrentlyActiveStep(step));
    };
    const updateStepper = (newStepper: IStepperItem_SELL_NEW[]) => {
        dispatch(setStepper(newStepper));
    };

    const updatePath = (updatePath: TStepperPath) => {
        dispatch(setPath(updatePath));
    };

    const goToSpecificStep = (step: StepperStep) => {
        const targetStep = getSpecificStep(step);
        if (!targetStep) return;
        updateCurrentlyActiveStep(targetStep);
        return targetStep;
    };

    const goToPreviousStep = (currentStep: StepperStep) => {
        const targetStep = getPreviousStep(currentStep);

        if (!targetStep) return;
        updateCurrentlyActiveStep(targetStep);
        return targetStep;
    };
    const goToLastActiveStep = () => {
        const targetStep = getLastActiveStep();
        if (!targetStep) return;
        updateCurrentlyActiveStep(targetStep);
    };

    const getCurrentActiveStep = () => {
        return currentlyActiveStep;
    };

    const manuallyDisableStep = (
        step: StepperStep,
        stepper: IStepperItem_SELL_NEW[]
    ) => {
        const modifiedStepper = stepper.map(stp => {
            if (step === stp.label)
                return {
                    ...stp,
                    activated: false,
                    allowed: false,
                    manuallyDisabled: true,
                };
            return stp;
        });
        return modifiedStepper;
    };

    const manuallyEnableStep = (
        step: StepperStep,
        stepper: IStepperItem_SELL_NEW[]
    ) => {
        const modifiedStepper = stepper.map(stp => {
            if (step === stp.label) return { ...stp, manuallyDisabled: false };
            return stp;
        });

        const stepsWithAllowedAdjusted = adjustAllowedStep(
            step,
            modifiedStepper,
            path
        );

        // activate next step
        const resObj = activateNextStepChainer(stepsWithAllowedAdjusted);

        if (resObj) {
            const { newStepper } = resObj;

            return newStepper;
        }
        return stepsWithAllowedAdjusted;
    };

    const updateStepperWhenAStepIsFilledChainer = (
        step: StepperStep,
        stepValues: IStepValues,
        path: TStepperPath
    ) => {
        // fill target step
        const filledSteps = fillStep(step, stepValues);
        // console.log('filledSteps', filledSteps);
        // reset comming steps
        const fiiledAndResetCommingSteps =
            resetStepperValuesAfterCertainStepValueChangeChainer(
                step,
                filledSteps
            );
        // console.log('fiiledAndResetCommingSteps', fiiledAndResetCommingSteps);
        // adjust allowed steps based on selected values
        const stepsWithAllowedAdjusted = adjustAllowedStep(
            step,
            fiiledAndResetCommingSteps,
            path
        );
        // console.log('stepsWithAllowedAdjusted', stepsWithAllowedAdjusted);

        // adjust steps numbers
        const stepperWithAdjustedNumber = adjustStepNumberBasedOnAllowedValue(
            stepsWithAllowedAdjusted
        );

        // activate next step
        const resObj = activateNextStepChainer(stepperWithAdjustedNumber);
        // console.log('activateNextStepChainer', resObj);
        if (resObj) {
            const { activeStep, newStepper } = resObj;
            // update cuurently active step
            updateCurrentlyActiveStep(activeStep);
            updateStepper(newStepper);
            //update url
            changeUrlBasedOnCurrentStep(activeStep.label);
            return newStepper;
        }
        updateStepper(stepperWithAdjustedNumber);
        return stepperWithAdjustedNumber;
    };

    return {
        intializeStepperSteps,
        goToSpecificStep,
        goToPreviousStep,
        goToLastActiveStep,
        resetStepper,
        resetStepperAfterPreviousStepChange,
        getStepData,
        updateStepperWhenAStepIsFilledChainer,
        updateStepper,
        getCurrentActiveStep,
        getStepper,
        getPath,
        updatePath,
        changeUrlBasedOnCurrentStep,
        manuallyDisableStep,
        manuallyEnableStep,
    };
};
export default useSellStepper;
