'use client';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    CalendarIconGreen,
    CalendarIconOrange,
    ContactIconGreen,
    ContactIconOrange,
    KMIconGreen,
    KMIconOrange,
    LocationIconGreen,
    LocationIconOrange,
    MakeIconGreen,
    MakeIconOrange,
    ModelIconGreen,
    ModelIconOrange,
    otherInfoIcon,
} from '../../../public/Images';
import {
    clearPath1Data,
    setPath,
    setPath1ContactInfo,
    setPath1Kilometers,
    setPath1Make,
    setPath1Model,
    setPath1Region,
    setPath1TrafficPoint,
    setPath1Transmission,
    setPath1Year,
    setStepper,
    setStepperContactInfo,
    setStepperCurrentActiveStep,
    setStepperKilometers,
    setStepperMake,
    setStepperModel,
    setStepperOtherInfo,
    setStepperRegion,
    setStepperYear,
} from '@/redux/reducers/sell-car-form.reducer';
import {
    EInspectionType,
    IContanctInfo_SELL,
    ICoordinate,
    IKilometrage_SELL,
    ILeadRequest,
    IMake_SELL,
    IMakesApi_SELL,
    IModel_SELL,
    IModelApi_SELL,
    IPerspectiveLeadRequest,
    IRegion_SELL,
    ISellCarForm,
    ISpecificContactTime,
    IStepper_SELL,
    IStepperItem_SELL,
    ITrafficPoint_SELL,
    ITransmission_SELL,
    IYear_SELL,
    IYearsKm_SELL,
    Region,
} from '@/types';

import {
    useAppSelector,
    useExperiment,
    useLocalStorage,
    useTranslation,
} from '@/hook';
import { useAnalytics } from '@/integrations';
import {
    NEXT_APP_ACQUISITION_EXPERIMENT_OTHER_INFO,
    SPECIFIC_CONTACT_EXPERIMENT,
} from '@/constants';
import { usePathname } from 'next/navigation';

export interface IFormatedData_SELL {
    selected: boolean;
    inShoppingList: boolean;
    icon: string;
    nameAr: string;
    nameEn: string;
    id: string;
}

export interface ISmallArea {
    id: string;
    nameEn: string;
    nameAr: string;
    warehouse: boolean;
    serviceable: boolean;
    selected: boolean;
    showAlert: boolean;
    polygon: ICoordinate[];
}

export interface ILocationDropDown {
    id: string;
    bigAreaEn: string;
    bigAreaAr: string;
    expanded: boolean;
    smallArea: ISmallArea[];
}

const useSellCar = () => {
    const dispatch = useDispatch();
    const {
        path1: sellCarPath1FormData,
        choosenPath,
        stepper,
        currentStep,
    } = useAppSelector(state => state.sellCarForm);
    const {
        make,
        model,
        year,
        kilometers,
        contactInfo,
        region,
        transmission,
        trafficPoint,
    } = sellCarPath1FormData;
    // const his = createHashHistory();
    const { t, IS_RTL } = useTranslation();
    const { trackEvent } = useAnalytics();

    const pathName = usePathname();
    // const searchParams = useSearchParams()!;
    const { setItem } = useLocalStorage();
    const [contactTimeUsed, setContactTimeUsed] = useState(false);
    const [contactTimeObject, setContactTimeObject] = useState({});
    const contactTimeFlag = useExperiment(SPECIFIC_CONTACT_EXPERIMENT);
    const addContactTime = contactTimeFlag === '2';

    const otherInfoExperiment = useExperiment(
        NEXT_APP_ACQUISITION_EXPERIMENT_OTHER_INFO
    );

    const showOtherInfo = otherInfoExperiment === '1';

    const hideAllAlerts = true;
    // const hideAllAlerts = false;
    const getContactTimeObject = () => {
        return contactTimeObject;
    };
    const getContactTimeUsed = () => {
        return contactTimeUsed;
    };
    const getAddContactTime = () => {
        return addContactTime;
    };
    const setStepperObject = (leadData: ISellCarForm) => {
        const yearPath1Flag =
            leadData?.year?.year === ''
                ? choosenPath === 'path1'
                : leadData?.year?.inShoppingList;
        const modelPath1Flag =
            leadData?.model.nameEn === ''
                ? choosenPath === 'path1'
                : leadData?.model?.inShoppingList;

        const otherInfoFilled = showOtherInfo
            ? leadData.transmission.transmission !== '' &&
              leadData.trafficPoint.trafficPoint !== ''
            : leadData.kilometers.displayed !== '';

        const otherInfoInShoppingList =
            leadData.transmission.inShoppingList &&
            leadData.trafficPoint.inShoppingList;

        const isPath1ForRegion = showOtherInfo
            ? (otherInfoInShoppingList && choosenPath === 'path1') ||
              (choosenPath === 'path2' &&
                  region.bigArea !== '' &&
                  otherInfoInShoppingList)
            : leadData.kilometers.inShoppingList || choosenPath === 'path1';

        const allowContactInfoPath1 =
            leadData.region.bigArea !== '' ||
            leadData.region.addressCity !== '';
        const allowContactInfoPath2 = isPath1ForRegion
            ? leadData.region.bigArea !== '' ||
              leadData.region.addressCity !== ''
            : otherInfoFilled;

        const stepperObject: IStepper_SELL = {
            inspectionType: {
                active: true,
                allowed: true,
                label: 'inspectionType',
                icon: MakeIconGreen,
                step: 1,
                iconValue: leadData?.make?.image ? leadData?.make?.image : '',
                value: IS_RTL
                    ? leadData?.make?.nameAr || ''
                    : leadData?.make?.nameEn || '',
                mainIcon:
                    make.inShoppingList || hideAllAlerts
                        ? MakeIconGreen
                        : MakeIconOrange,
                backgroundColor:
                    make.inShoppingList || hideAllAlerts
                        ? '#e6fbf2'
                        : '#FFFAEB',
                inShoppingList: leadData?.make?.inShoppingList,
                showAlert: !(leadData?.make?.inShoppingList || hideAllAlerts),
            },
            make: {
                active: true,
                allowed: true,
                label: 'make',
                icon: MakeIconGreen,
                step: 1,
                iconValue: leadData?.make?.image ? leadData?.make?.image : '',
                value: IS_RTL
                    ? leadData?.make?.nameAr || ''
                    : leadData?.make?.nameEn || '',
                mainIcon:
                    make.inShoppingList || hideAllAlerts
                        ? MakeIconGreen
                        : MakeIconOrange,
                backgroundColor:
                    make.inShoppingList || hideAllAlerts
                        ? '#e6fbf2'
                        : '#FFFAEB',
                inShoppingList: leadData?.make?.inShoppingList,
                showAlert: !(leadData?.make?.inShoppingList || hideAllAlerts),
            },
            model: {
                active: leadData.make.id !== '',
                allowed: true,
                label: 'model',
                icon: ModelIconGreen,
                step: 2,
                iconValue:
                    leadData?.make?.image && leadData?.model?.nameEn
                        ? leadData?.make?.image
                        : '',
                value: IS_RTL
                    ? leadData?.model?.nameAr || ''
                    : leadData?.model?.nameEn || '',
                backgroundColor:
                    modelPath1Flag || hideAllAlerts ? '#e6fbf2' : '#FFFAEB',
                mainIcon:
                    modelPath1Flag || hideAllAlerts
                        ? ModelIconGreen
                        : ModelIconOrange,
                inShoppingList: leadData?.model?.inShoppingList,
                showAlert: !(modelPath1Flag || hideAllAlerts),
            },
            year: {
                active: leadData.model.id !== '',
                allowed: true,
                label: 'year',
                icon: CalendarIconGreen,
                step: 3,
                iconValue: '',
                value: leadData?.year?.year !== '' ? leadData?.year?.year : '',
                backgroundColor:
                    yearPath1Flag || hideAllAlerts ? '#e6fbf2' : '#FFFAEB',
                mainIcon:
                    yearPath1Flag || hideAllAlerts
                        ? CalendarIconGreen
                        : CalendarIconOrange,
                inShoppingList: leadData?.year?.inShoppingList,
                showAlert: !(yearPath1Flag || hideAllAlerts),
            },
            kilometers: {
                active: leadData.year?.year !== '',
                allowed: true,
                label: 'kilometers',
                icon: KMIconGreen,
                step: 4,
                iconValue: '',
                value: leadData?.kilometers.displayed
                    ? leadData?.kilometers?.displayed
                    : '',
                backgroundColor:
                    choosenPath === 'path1' || hideAllAlerts
                        ? '#e6fbf2'
                        : '#FFFAEB',
                mainIcon:
                    choosenPath === 'path1' || hideAllAlerts
                        ? KMIconGreen
                        : KMIconOrange,
                inShoppingList:
                    leadData?.kilometers?.displayed !== ''
                        ? leadData?.kilometers?.inShoppingList
                        : choosenPath === 'path1',
                showAlert: hideAllAlerts
                    ? false
                    : leadData?.kilometers?.displayed !== ''
                    ? !leadData?.kilometers?.inShoppingList
                    : choosenPath !== 'path1',
            },
            otherinfo: {
                active: showOtherInfo && leadData.kilometers.displayed !== '',
                allowed: showOtherInfo,
                icon: otherInfoIcon,
                iconValue: '',
                label: 'otherinfo',
                step: showOtherInfo ? 5 : -1,
                value: getOtherInfoLabel(),
                inShoppingList:
                    leadData.transmission.inShoppingList &&
                    leadData.trafficPoint.inShoppingList,
                backgroundColor: hideAllAlerts ? '#e6fbf2' : '#FFFAEB',
                mainIcon: otherInfoIcon,
                showAlert: false,
            },
            region: {
                active: otherInfoFilled,
                allowed: isPath1ForRegion,
                label: 'region',
                icon: LocationIconGreen,
                step:
                    choosenPath === 'path1' ||
                    leadData?.kilometers?.inShoppingList
                        ? showOtherInfo
                            ? 6
                            : 5
                        : -1,
                iconValue: '',
                value: leadData?.region?.smallArea
                    ? IS_RTL
                        ? leadData?.region?.smallAreaAr
                        : leadData?.region?.smallArea
                    : leadData?.region?.addressCity,
                backgroundColor:
                    choosenPath === 'path1' ||
                    leadData?.kilometers?.inShoppingList
                        ? '#e6fbf2'
                        : '#FFFAEB',
                mainIcon:
                    choosenPath === 'path1' ||
                    leadData?.kilometers?.inShoppingList
                        ? LocationIconGreen
                        : LocationIconOrange,
                inShoppingList: true,
                showAlert: false,
            },
            contactInfo: {
                active:
                    choosenPath === 'path1'
                        ? allowContactInfoPath1
                        : allowContactInfoPath2,
                allowed: true,
                label: 'contact',
                icon: ContactIconGreen,
                step: getContactStep(),
                iconValue: '',
                value: leadData?.contactInfo?.phone
                    ? leadData?.contactInfo?.phone
                    : '',
                backgroundColor:
                    choosenPath === 'path1' || hideAllAlerts
                        ? '#e6fbf2'
                        : '#FFFAEB',
                mainIcon:
                    choosenPath === 'path1' || hideAllAlerts
                        ? ContactIconGreen
                        : ContactIconOrange,
                inShoppingList: choosenPath === 'path1',
                showAlert: !(choosenPath === 'path1' || hideAllAlerts),
            },
        };
        dispatch(setStepper({ ...stepperObject }));
    };
    const getTotalStepperSteps = () => {
        const totalSteps = Object.keys(stepper).filter(
            key => stepper[key as keyof typeof stepper].step !== -1
        );
        return totalSteps.length;
    };
    const updateCurrentActiveStep = (step: IStepperItem_SELL) => {
        dispatch(setStepperCurrentActiveStep(step));
    };

    const getCurrentActiveStep = () => {
        return currentStep;
    };

    // const createQueryString = useCallback(
    //   (name: string, value: string) => {
    //     const params = new URLSearchParams();
    //     params.set(name, value);

    //     return params.toString();
    //   },
    //   [searchParams],
    // );

    const getStepperLastActiveStep = (step?: keyof typeof stepper) => {
        let lastActiveStep = '';
        Object.keys(stepper).forEach(key => {
            if (
                stepper[key as keyof typeof stepper].active &&
                stepper[key as keyof typeof stepper].allowed
            )
                lastActiveStep = key;
        });
        const targetStep: string = step ? (step as string) : lastActiveStep;
        const location = pathName;

        if (
            targetStep !== pathName &&
            location.includes('sell-form') &&
            !location.includes('thankyou')
        ) {
            // createQueryString('step', targetStep);
            window.location.hash = targetStep;
            // router.push(`sell-form?step=${targetStep}`);
        }
        updateCurrentActiveStep(stepper[targetStep as keyof typeof stepper]);
    };

    const storeFormData = (updatedLeadData: ISellCarForm) => {
        setItem('@leadFormData', updatedLeadData);
    };
    // const getFormData = () => {
    //     const storedLeadData = getItem<ISellCarForm>('@leadFormData');
    //     if (storedLeadData) {
    //         dispatch(setPath1IntialState(storedLeadData));
    //         setStepperObject(storedLeadData);
    //     }
    // };

    const getPreviousStep = (currentStep: keyof IStepper_SELL) => {
        if (currentStep === 'make') return;
        const keys = Object.keys(stepper);
        const currentStepIndex = keys.findIndex(key => key === currentStep);
        for (let i = currentStepIndex - 1; i >= 0; i -= 1) {
            if (stepper[keys[i] as keyof typeof stepper].allowed) {
                const targetStep = keys[i];
                return targetStep;
            }
        }
        // const targrtStep = stepper[keys[currentStepIndex - 1] as keyof typeof stepper].allowed
        //   ? keys[currentStepIndex - 1]
        //   : keys[currentStepIndex - 2];
        // return targrtStep;
    };

    const goBackToPreviousStep = (currentStep: keyof IStepper_SELL) => {
        if (currentStep === 'make') {
            getStepperLastActiveStep('make');
            return;
        }
        const prevStep = getPreviousStep(currentStep);
        if (prevStep) getStepperLastActiveStep(prevStep as keyof IStepper_SELL);
    };

    const backButtonClicked = (pathName: keyof typeof stepper) => {
        getStepperLastActiveStep(pathName);
    };
    // useEffect(() => {
    //   getFormData();
    // }, []);
    useEffect(() => {
        storeFormData(sellCarPath1FormData);
        setStepperObject(sellCarPath1FormData);
    }, [sellCarPath1FormData]);

    useEffect(() => {
        getStepperLastActiveStep();
        window.addEventListener('popstate', () => {
            const pathName = window.location.hash.substring(1);
            backButtonClicked(pathName as keyof typeof stepper);
        });
        // if (window?.onhashchange) {
        //   return window.onhashchange((location: HashChangeEvent) => {
        //     if (location.action === 'POP') {
        //       const pathName = location.location?.pathname;
        //       backButtonClicked(pathName as keyof typeof stepper);
        //     }
        //   });
        // }
    }, [stepper]);

    const getMake = () => {
        return make;
    };
    const getModel = () => {
        return model;
    };
    const getRegion = () => {
        return region;
    };
    const getYear = () => {
        return year;
    };
    const getKilometers = () => {
        return kilometers;
    };
    const getContactInfo = () => {
        return contactInfo;
    };
    const getPath = () => {
        return choosenPath;
    };
    const getTransmission = () => {
        return transmission;
    };
    const getTrafficPoint = () => {
        return trafficPoint;
    };

    const getStepperStep = (step: keyof IStepper_SELL) => {
        if (step === 'make') return stepper.make;
        if (step === 'model') return stepper.model;
        if (step === 'year') return stepper.year;
        if (step === 'kilometers') return stepper.kilometers;
        if (step === 'region') return stepper.region;
        if (step === 'otherinfo') return stepper.otherinfo;
        return stepper.contactInfo;
    };

    const modifyStepperSellObject = (
        key: keyof IStepper_SELL,
        value: string,
        valueIcon?: string
    ) => {
        const stepperObject = getStepperStep(key);
        const modifiedObject: IStepperItem_SELL = {
            ...stepperObject,
            value: value,
            active: value !== '',
            iconValue: valueIcon ? valueIcon : '',
        };
        return modifiedObject;
    };

    const setStepperStep = (
        step: keyof IStepper_SELL,
        value: string,
        iconValue?: string
    ) => {
        if (step === 'make')
            dispatch(
                setStepperMake(modifyStepperSellObject(step, value, iconValue))
            );
        if (step === 'model')
            dispatch(
                setStepperModel(modifyStepperSellObject(step, value, iconValue))
            );
        if (step === 'year')
            dispatch(
                setStepperYear(modifyStepperSellObject(step, value, iconValue))
            );
        if (step === 'kilometers')
            dispatch(
                setStepperKilometers(
                    modifyStepperSellObject(step, value, iconValue)
                )
            );
        if (step === 'region')
            dispatch(
                setStepperRegion(
                    modifyStepperSellObject(step, value, iconValue)
                )
            );
        if (step === 'contactInfo')
            dispatch(
                setStepperContactInfo(
                    modifyStepperSellObject(step, value, iconValue)
                )
            );
        if (step === 'otherinfo')
            dispatch(
                setStepperOtherInfo(
                    modifyStepperSellObject(step, value, iconValue)
                )
            );
    };

    const clearStepperSubsequentSteps = (currentStep: keyof IStepper_SELL) => {
        const currentStepObj = getStepperStep(currentStep);
        const currentStepNumber = currentStepObj.step;
        Object.keys(stepper).forEach((key, index) => {
            if (index >= currentStepNumber) {
                setStepperStep(key as keyof IStepper_SELL, '');

                if (key === 'region')
                    buildRegionFormObject('region', {
                        id: '',
                        bigArea: '',
                        smallArea: '',
                        name: '',
                        bigAreaAr: '',
                        smallAreaAr: '',
                        address: '',
                        coordinate: null,
                        polygon: [],
                        serviceable: false,
                        warehouse: false,
                        addressCity: '',
                        sendPinLocation: false,
                    });
                else if (key === 'contactInfo')
                    buildContactInfoFormObject('contactInfo', {
                        name: '',
                        phone: '',
                        email: '',
                        userId: '',
                    });
                else if (key === 'otherinfo') {
                    buildFormObject('transmission', {
                        selected: false,
                        inShoppingList: true,
                        icon: '',
                        nameAr: '',
                        nameEn: '',
                        id: '',
                    });
                    buildFormObject('trafficPoint', {
                        selected: false,
                        inShoppingList: true,
                        icon: '',
                        nameAr: '',
                        nameEn: '',
                        id: '',
                    });
                } else
                    buildFormObject(key as keyof ISellCarForm, {
                        selected: false,
                        inShoppingList: true,
                        icon: '',
                        nameAr: '',
                        nameEn: '',
                        id: '',
                    });
            }
        });
    };

    // ________________________________________FORMAT FETCHED DATA START______________________________________

    const sortDisplayedDataBasedOnShoppingList = (
        displayedArray: IFormatedData_SELL[]
    ) => {
        // sort function sorts an array in place
        displayedArray.sort((a, b) => {
            if (a.inShoppingList && !b.inShoppingList) {
                return -1; // a should come before b
            } else if (!a.inShoppingList && b.inShoppingList) {
                return 1; // b should come before a
            } else {
                return 0; // no sorting needed
            }
        });
    };

    const formatMakesForDisplay = (
        makes: IMakesApi_SELL[]
    ): IFormatedData_SELL[] => {
        if (!makes) return [];
        const currentSelectedMake = getMake();
        const formatedMakes: IFormatedData_SELL[] = makes?.map(make => {
            const diplayObj: IFormatedData_SELL = {
                selected: currentSelectedMake.nameEn === make.enName,
                icon: make.image,
                nameAr: make.arName,
                nameEn: make.enName,
                id: make.id,
                inShoppingList: make.inShoppingList,
            };
            return diplayObj;
        });
        sortDisplayedDataBasedOnShoppingList(formatedMakes);
        return formatedMakes;
    };
    const checkIfModelInShoppingList = (yearKm: IYearsKm_SELL[]) => {
        let isModelInShoppingList = false;
        yearKm.forEach(year => {
            if (year.inShoppingList) {
                isModelInShoppingList = true;
            }
        });
        return isModelInShoppingList;
    };

    const formatModelsForDisplay = (
        models: IModelApi_SELL[]
    ): IFormatedData_SELL[] => {
        const currentSelectedModel = getModel();
        const formatedModels: IFormatedData_SELL[] = models.map(model => {
            const inShoppingList = checkIfModelInShoppingList(model.yearsKm);
            const displayObj: IFormatedData_SELL = {
                selected: currentSelectedModel.nameEn === model.enName,
                icon: make.image,
                nameAr: model.arName,
                nameEn: model.enName,
                id: model.id,
                inShoppingList: inShoppingList,
            };
            return displayObj;
        });
        sortDisplayedDataBasedOnShoppingList(formatedModels);
        return formatedModels;
    };

    const formatYearsForDisplay = (
        model: IModelApi_SELL
    ): IFormatedData_SELL[] => {
        const formatedYears: IFormatedData_SELL[] = model.yearsKm.map(
            yearKm => {
                const displayObj: IFormatedData_SELL = {
                    selected: false,
                    icon: make.image,
                    nameAr: yearKm.year,
                    nameEn: yearKm.year,
                    id: yearKm.year,
                    inShoppingList: yearKm.inShoppingList,
                };
                return displayObj;
            }
        );
        formatedYears.sort(
            (a, b) => parseFloat(b.nameEn) - parseFloat(a.nameEn)
        );

        return formatedYears;
    };

    const getTransmissionOptionsFromYear = (
        yearData: IYear_SELL,
        models: IModelApi_SELL[]
    ) => {
        const year = yearData.year;
        const targetModel = models?.filter(mdl => mdl.id === model.id);
        const manualAccepted = targetModel[0]?.yearsKm.filter(
            yearKm => yearKm.year === year
        )[0].manualAccepted;

        const formDataObjects: IFormatedData_SELL[] = [
            {
                selected: false,
                inShoppingList: true,
                icon: '',
                nameAr: t('automatic'),
                nameEn: t('automatic'),
                id: 'automatic',
            },
            {
                selected: false,
                inShoppingList: manualAccepted,
                icon: '',
                nameAr: t('manual'),
                nameEn: t('manual'),
                id: 'manual',
            },
        ];
        return formDataObjects;
    };

    const getTrafficPointOptions = () => {
        const formDataObjects: IFormatedData_SELL[] = [
            {
                selected: false,
                inShoppingList: true,
                icon: '',
                nameAr: t('cairo'),
                nameEn: t('cairo'),
                id: 'cairo',
            },
            {
                selected: false,
                inShoppingList: true,
                icon: '',
                nameAr: t('giza'),
                nameEn: t('giza'),
                id: 'giza',
            },
            {
                selected: false,
                inShoppingList: false,
                icon: '',
                nameAr: t('others'),
                nameEn: t('others'),
                id: 'others',
            },
        ];
        return formDataObjects;
    };

    const getTargetModel = (
        model: IModel_SELL,
        models: IModelApi_SELL[]
    ): IModelApi_SELL => {
        const targetModel = models?.filter(mdl => mdl.id === model.id);
        return targetModel[0];
    };

    const getYearsKmFromTargetModel = (allModels: IModelApi_SELL[]) => {
        const selectedModel = getModel();

        const targetFetchedModel = getTargetModel(selectedModel, allModels);
        return formatYearsForDisplay(targetFetchedModel);
    };
    const generateKilometrages = (defaultMaxKM = '130000') => {
        const maxKM = +'220000';
        let start = 0;
        let end = 9999;
        const step = 10000;
        const result = [];
        while (end + 1 < maxKM) {
            result.push({
                original: `${start}-${end}`,
                formatted: `${start.toLocaleString()} - ${end.toLocaleString()}`,
                stepperDisplay: `${start.toLocaleString()}-${end.toLocaleString()}`,
                inShoppingList: end < +defaultMaxKM && end > 19999,
            });
            start += step;
            end += step;
        }
        if (start < maxKM) {
            result.push({
                original: `${start}-${maxKM}`,
                formatted: `${start.toLocaleString()} - ${maxKM.toLocaleString()}`,
                stepperDisplay: `${start.toLocaleString()}-${maxKM.toLocaleString()}`,
                inShoppingList: end < +defaultMaxKM && maxKM > 19999,
            });
        }

        return result;
    };
    const formatKilometersForDisplay = (
        yearKm: IYearsKm_SELL
    ): IFormatedData_SELL[] => {
        const generatedKilometers = generateKilometrages(yearKm.km);
        const selectedYear = getYear();
        const formatedKm = generatedKilometers.map(km => {
            const displayObj: IFormatedData_SELL = {
                selected: false,
                icon: make.image,
                nameAr: km.formatted,
                nameEn: km.formatted,
                id: km.original,
                inShoppingList: selectedYear?.inShoppingList
                    ? km.inShoppingList
                    : false,
            };
            return displayObj;
        });
        return formatedKm;
    };

    const getTargetYearKm = (
        model: IModel_SELL,
        year: string,
        models: IModelApi_SELL[]
    ): IYearsKm_SELL => {
        const targetModel = models?.filter(mdl => mdl.id === model.id);
        const targetYearKm = targetModel[0].yearsKm.filter(
            yearKm => yearKm.year === year
        );
        return targetYearKm[0];
    };
    const getKmFromTargetModel = (allModels: IModelApi_SELL[]) => {
        const selectedModel = getModel();
        const selectedYear = getYear();
        const targetFetchedModel = getTargetYearKm(
            selectedModel,
            selectedYear.year,
            allModels
        );
        return formatKilometersForDisplay(targetFetchedModel);
    };

    const formatRegionsForDisplay = (
        regions: Region[]
    ): ILocationDropDown[] => {
        const formatedLocations: ILocationDropDown[] = regions?.map(bigArea => {
            const smallAreas: ISmallArea[] = bigArea?.smallArea?.map(
                smallArea => {
                    const area: ISmallArea = {
                        id: smallArea.id,
                        nameEn: smallArea.name,
                        nameAr: smallArea.arName,
                        selected: false,
                        showAlert: false,
                        polygon: smallArea.polygon,
                        warehouse: smallArea.warehouse,
                        serviceable: smallArea.serviceable,
                    };
                    return area;
                }
            );
            const location: ILocationDropDown = {
                id: bigArea.id,
                bigAreaEn: bigArea.bigArea,
                bigAreaAr: bigArea.arBigArea,
                expanded: false,
                smallArea: [...smallAreas],
            };
            return location;
        });
        return formatedLocations;
    };
    // ________________________________________FORMAT FETCHED DATA END______________________________________
    const updateMake = (updatedMake: IMake_SELL) => {
        dispatch(setPath1Make(updatedMake));
        return;
    };
    const updateModel = (updatedModel: IModel_SELL) => {
        dispatch(setPath1Model(updatedModel));
        return;
    };
    const updateRegion = (updatedRegion: IRegion_SELL) => {
        dispatch(setPath1Region(updatedRegion));
    };
    const updateYear = (updatedYear: IYear_SELL) => {
        dispatch(setPath1Year(updatedYear));
        return;
    };
    const updateKilometers = (updatedKilometers: IKilometrage_SELL) => {
        dispatch(setPath1Kilometers(updatedKilometers));
        return;
    };
    const updateContactInfo = (updatedInfo: IContanctInfo_SELL) => {
        dispatch(setPath1ContactInfo(updatedInfo));
        return;
    };
    const updateTransmission = (updatedInfo: ITransmission_SELL) => {
        dispatch(setPath1Transmission(updatedInfo));
        return;
    };
    const updateTraffficPoint = (updatedInfo: ITrafficPoint_SELL) => {
        dispatch(setPath1TrafficPoint(updatedInfo));
        return;
    };

    const updatePath = (
        updatedPath: 'path1' | 'path2',
        resetRegion: boolean
    ) => {
        dispatch(setPath(updatedPath));
        if (resetRegion) {
            const regionEmptyObject: IRegion_SELL = {
                id: '',
                bigArea: '',
                smallArea: '',
                name: '',
                bigAreaAr: '',
                smallAreaAr: '',
                address: '',
                coordinate: null,
                polygon: [],
                serviceable: false,
                warehouse: false,
                addressCity: '',
                sendPinLocation: false,
            };
            dispatch(setPath1Region(regionEmptyObject));
        }
    };
    // ________________________________________Analytics START______________________________________
    const triggerAnalytics = (
        step: keyof IStepper_SELL,
        item: IFormatedData_SELL
    ) => {
        if (step === 'make') {
            trackEvent('Sell_Make', {
                make: item.nameEn,
                available: item.inShoppingList,
            });
        }
        if (step === 'model') {
            trackEvent('Sell_Model', {
                make: make.nameEn,
                model: item.nameEn,
                available: item.inShoppingList,
            });
        }

        if (step === 'year') {
            trackEvent('Sell_Year', {
                make: make.nameEn,
                model: model.nameEn,
                year: item.nameEn,
                available: item.inShoppingList,
            });
        }
        if (step === 'kilometers') {
            trackEvent('Sell_Kilometers', {
                make: make.nameEn,
                model: model.nameEn,
                year: year.year,
                kilometers: item.nameEn.replace(/,/g, '').split(' - ')[1],
                available: item.inShoppingList,
            });
        }

        if (step === 'contactInfo') {
            trackEvent('Sell_Personal_Details', {
                make: make.nameEn,
                model: model.nameEn,
                year: year.year,
                kilometers: kilometers.value.toString(),
                area: region?.bigArea,
                zone: region?.smallArea,
                name: contactInfo.name,
                mobileNumber: contactInfo.phone,
                email: contactInfo?.email,
                available: choosenPath === 'path1',
            });
        }
    };
    // const triggerEventRegion = (
    //     key: keyof ISellCarForm,
    //     item: IRegion_SELL
    // ) => {
    //     trackEvent('Sell_Region', {
    //         make: make.nameEn,
    //         model: model.nameEn,
    //         year: year.year,
    //         kilometers: kilometers.value.toString(),
    //         area: item?.bigArea,
    //         zone: item?.smallArea,
    //         available: choosenPath === 'path1',
    //     });
    // };
    const triggerEventPinLocation = (item: IRegion_SELL) => {
        trackEvent('Sell_Pin_Location', {
            make: make.nameEn,
            model: model.nameEn,
            year: year.year,
            kilometers: kilometers.value.toString(),
            area: item?.bigArea,
            zone: item?.smallArea,
        });
    };
    const triggerEventContactInfo = (
        key: keyof ISellCarForm,
        item: IContanctInfo_SELL
    ) => {
        trackEvent('Sell_Personal_Details', {
            make: make.nameEn,
            model: model.nameEn,
            year: year.year,
            kilometers: kilometers.value.toString(),
            area: region?.bigArea,
            zone: region?.smallArea,
            name: item.name,
            mobileNumber: item.phone,
            email: item?.email,
            available: choosenPath === 'path1',
        });
    };
    const triggerSpecificContactTimeEvent = (
        timeObject: ISpecificContactTime
    ) => {
        trackEvent('Sell_Car_Specific_Time_Selected', {
            date: timeObject.date,
            from: timeObject.from,
            to: timeObject.to,
            fromTo: timeObject.fromTo,
        });
    };
    // _________________________________________Analytics END______________________________________
    const buildFormObject = (
        key: keyof ISellCarForm,
        item: IFormatedData_SELL
    ) => {
        if (key === 'make') {
            const makeObj: IMake_SELL = {
                nameAr: item.nameAr,
                nameEn: item.nameEn,
                id: item.id,
                image: item.icon,
                inShoppingList: item.inShoppingList,
            };
            updateMake(makeObj);
            return;
        }
        if (key === 'model') {
            const modelObj: IModel_SELL = {
                nameAr: item.nameAr,
                nameEn: item.nameEn,
                id: item.id,
                inShoppingList: item.inShoppingList,
            };
            updateModel(modelObj);
            return;
        }
        if (key === 'year') {
            const year: IYear_SELL = {
                year: item.nameEn,
                inShoppingList: item?.inShoppingList,
            };
            updateYear(year);
            return;
        }
        if (key === 'kilometers') {
            const kilometersObj: IKilometrage_SELL = {
                value: parseFloat(
                    item.nameEn.replace(/,/g, '').split(' - ')[1]
                ),
                displayed: item.nameEn,
                inShoppingList: item?.inShoppingList,
            };
            updateKilometers(kilometersObj);
            return;
        }
        if (key === 'transmission') {
            const transmissionObj: ITransmission_SELL = {
                transmission: item.nameEn,
                inShoppingList: item?.inShoppingList,
                id: item?.id,
            };
            updateTransmission(transmissionObj);
            return;
        }
        if (key === 'trafficPoint') {
            const trafficPointObj: ITrafficPoint_SELL = {
                trafficPoint: item.nameEn,
                inShoppingList: item?.inShoppingList,
                id: item?.id,
            };
            updateTraffficPoint(trafficPointObj);
            return;
        }
    };

    const getOtherInfoLabel = () => {
        const transmissionString = transmission?.transmission
            ? transmission?.transmission
            : '';
        const trafficPointString = trafficPoint.trafficPoint
            ? trafficPoint.trafficPoint
            : '';

        if (transmissionString !== '' && trafficPointString !== '') {
            return transmissionString + ', ' + trafficPointString;
        } else {
            return transmissionString || trafficPointString || '';
        }
    };

    const getOtherInfoFlag = () => {
        return showOtherInfo;
    };

    const disableRegion = () => {
        if (showOtherInfo) {
            return (
                getTransmission().transmission === '' ||
                getTrafficPoint().trafficPoint === '' ||
                !getTransmission().inShoppingList ||
                !getTrafficPoint().inShoppingList ||
                getPath() === 'path2'
            );
        } else {
            return getPath() === 'path2';
        }
    };

    const regionShowCheck = (step: number) => {
        const regionStep = showOtherInfo ? 6 : 5;
        const otherInfoCheck = showOtherInfo
            ? getTransmission().inShoppingList &&
              getTrafficPoint().inShoppingList
            : kilometers.inShoppingList;

        return (
            step === regionStep &&
            (choosenPath === 'path1' ||
                (otherInfoCheck && choosenPath === 'path2'))
        );
    };

    const getContactStep = () => {
        const otherInfoFilled =
            transmission.transmission !== '' &&
            trafficPoint.trafficPoint !== '';
        const otherInfoPath1 =
            transmission.inShoppingList && trafficPoint.inShoppingList;
        const kilometersIn = kilometers.inShoppingList;
        //other info and region -> step 7
        if (showOtherInfo && region.bigArea !== '') return 7;
        // region only or otherinfo only -> step 6
        if (
            (showOtherInfo &&
                otherInfoFilled &&
                choosenPath === 'path2' &&
                !otherInfoPath1) ||
            (!showOtherInfo && region.bigArea !== '' && kilometersIn)
        )
            return 6;
        // no region and no other info ->step 5
        if (!showOtherInfo && choosenPath === 'path2' && !kilometersIn)
            return 5;
        return showOtherInfo ? 7 : 6;
    };

    const enableSubmitButton = () => {
        const make = getMake();
        const model = getModel();
        const year = getYear();
        const km = getKilometers();
        const region = getRegion();
        const path = getPath();
        const transmission = getTransmission();
        const trafficPoint = getTrafficPoint();

        const otherInfoFlag = showOtherInfo
            ? transmission?.transmission !== '' &&
              trafficPoint?.trafficPoint !== ''
            : true;

        return (
            (make.id !== '' &&
                model.id !== '' &&
                year.year !== '' &&
                km.value &&
                region?.smallArea &&
                otherInfoFlag &&
                path === 'path1') ||
            (make.id !== '' &&
                model.id !== '' &&
                year.year !== '' &&
                km.value &&
                otherInfoFlag &&
                path === 'path2')
        );
    };

    const buildRegionFormObject = (
        key: keyof ISellCarForm,
        item: IRegion_SELL
    ) => {
        const regionObj: IRegion_SELL = {
            id: item?.id,
            bigArea: item?.bigArea,
            smallArea: item?.smallArea,
            name: item?.name,
            smallAreaAr: item?.smallAreaAr,
            bigAreaAr: item?.bigAreaAr,
            address: item?.address,
            coordinate: item?.coordinate,
            polygon: item?.polygon,
            warehouse: item?.warehouse,
            serviceable: item?.serviceable,
            addressCity: item?.addressCity,
            sendPinLocation: item?.sendPinLocation,
        };
        updateRegion(regionObj);
    };
    const buildContactInfoFormObject = (
        key: keyof ISellCarForm,
        item: IContanctInfo_SELL
    ) => {
        const contactInfoObj: IContanctInfo_SELL = {
            name: item.name,
            phone: item.phone,
            email: item.email,
            userId: item.userId,
        };
        updateContactInfo(contactInfoObj);
    };

    const setChoosenData = (
        key: keyof ISellCarForm,
        item: IFormatedData_SELL
    ) => {
        const inShoppingList =
            make?.inShoppingList &&
            model?.inShoppingList &&
            year?.inShoppingList &&
            kilometers?.inShoppingList;

        if (key === 'trafficPoint') {
            if (
                transmission?.inShoppingList &&
                item?.inShoppingList &&
                inShoppingList
            ) {
                updatePath('path1', false);
            } else {
                updatePath('path2', true);
            }
            buildFormObject(key, item);
            triggerAnalytics('otherinfo', item);
            clearStepperSubsequentSteps('otherinfo');
            return;
        }

        if (key === 'transmission') {
            if (
                item?.inShoppingList &&
                trafficPoint?.inShoppingList &&
                inShoppingList
            ) {
                updatePath('path1', false);
            } else {
                updatePath('path2', true);
            }
            buildFormObject(key, item);
            triggerAnalytics('otherinfo', item);
            clearStepperSubsequentSteps('otherinfo');
            return;
        }

        if (!item.inShoppingList) updatePath('path2', true);
        if (item.inShoppingList) updatePath('path1', false);
        buildFormObject(key, item);
        triggerAnalytics(key, item);
        clearStepperSubsequentSteps(key);
        // setStepperStep(key, item.nameEn, item.icon);
    };

    const setChoosenDataRegion = (key: 'region', item: IRegion_SELL) => {
        if (!item.serviceable && !item.warehouse) updatePath('path2', false);
        if (item.serviceable || item.warehouse) updatePath('path1', false);
        triggerEventPinLocation(item);

        buildRegionFormObject(key, item);
        // setStepperStep(key, item.smallArea, '');
    };

    const setSellerContactInfo = (
        key: 'contactInfo',
        item: IContanctInfo_SELL
    ) => {
        buildContactInfoFormObject(key, item);
        triggerEventContactInfo(key, item);

        // setStepperStep(key, item.phone, '');
    };

    const buildLeadObject = (
        otp: string,
        lang: string,
        userInfo: IContanctInfo_SELL,
        specificContactTime?: ISpecificContactTime
    ) => {
        const leadObj: ILeadRequest = {
            year: year.year,
            name: userInfo.name,
            make: {
                id: make.id,
                enName: make.nameEn,
            },
            model: {
                id: model.id,
                enName: model.nameEn,
            },
            transmission: showOtherInfo ? transmission.transmission : 'NA',
            trafficUnit: showOtherInfo ? trafficPoint.trafficPoint : 'NA',
            region: region.smallArea,
            regionObject: {
                _id: region.id,
                bigArea: region.bigArea,
                smallArea: region.smallArea,
                name: region.smallArea,
                address: region.address,
                coordinate: region.sendPinLocation ? region.coordinate : null,
                addressCity: region.addressCity,
                serviceable: region.serviceable,
                warehouse: region.warehouse,
            },
            phone: userInfo.phone,
            phoneNumber: userInfo.phone,
            otp: otp,
            email: userInfo?.email,
            kilometers: kilometers.value,
            language: lang,
            specificContactTime: specificContactTime,
            inspectionType: EInspectionType.HOME,
        };
        if (addContactTime && specificContactTime && specificContactTime.date) {
            // triggerSpecificContactTimeEvent(specificContactTime);
            setContactTimeUsed(true);
            setContactTimeObject(specificContactTime);
        }
        // triggerVerificationEvent(userInfo);
        return leadObj;
    };

    const buildPerspectiveLeadObject = (
        lang: string,
        userInfo: IContanctInfo_SELL
    ) => {
        const perspectiveLeadObj: IPerspectiveLeadRequest = {
            year: year.year,
            name: userInfo.name,
            make: {
                id: make.id,
                enName: make.nameEn,
            },
            model: {
                id: model.id,
                enName: model.nameEn,
            },
            transmission: showOtherInfo ? transmission.transmission : 'NA',
            trafficUnit: showOtherInfo ? trafficPoint.trafficPoint : 'NA',
            regionObject: {
                _id: region.id,
                bigArea: region.bigArea,
                smallArea: region.smallArea,
                name: region.smallArea,
                address: region.address,
                coordinate: region.sendPinLocation ? region.coordinate : null,
                addressCity: region.addressCity,
                serviceable: region.serviceable,
                warehouse: region.warehouse,
            },
            phone: userInfo.phone,
            phoneNumber: userInfo.phone,
            email: userInfo?.email,
            kilometers: kilometers.value,
            language: lang,
            inspectionType: EInspectionType.HOME,
        };
        // triggerVerificationEvent(userInfo);
        return perspectiveLeadObj;
    };
    const clearForm = () => {
        dispatch(clearPath1Data());
    };
    const getDisclaimerText = () => {
        if (hideAllAlerts) return '';
        let disclaimerText = '';
        if (
            region.addressCity &&
            !region.name &&
            !region.warehouse &&
            !region.serviceable
        ) {
            disclaimerText = t('regionSellDisclaimerCase3', {
                location: region.addressCity,
            });
            return disclaimerText;
        }
        if (region.warehouse) {
            disclaimerText = t('regionSellDisclaimerCase2', {
                location: IS_RTL ? region.smallAreaAr : region.smallArea,
            });
            return disclaimerText;
        }
        if (choosenPath === 'path1') return disclaimerText;
        if (!make.inShoppingList) {
            disclaimerText = t('makeSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
            });
            return disclaimerText;
        }
        if (!model.inShoppingList) {
            disclaimerText = t('modelSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
                model: IS_RTL ? model.nameAr : model.nameEn,
            });
            return disclaimerText;
        }
        if (!year.inShoppingList) {
            disclaimerText = t('yearSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
                model: IS_RTL ? model.nameAr : model.nameEn,
                year: year?.year,
            });
            return disclaimerText;
        }
        if (!kilometers.inShoppingList) {
            disclaimerText = t('kmSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
                model: IS_RTL ? model.nameAr : model.nameEn,
                km: kilometers?.displayed.split('-')[0],
            });
            return disclaimerText;
        }

        return disclaimerText;
    };

    const shouldShowAlert = (key: keyof IStepper_SELL) => {
        if (hideAllAlerts) return false;
        if (key === 'make') return true;

        const prevStepKey = getPreviousStep(key);
        const prevStep = getStepperStep(prevStepKey as keyof IStepper_SELL);

        if (prevStep.inShoppingList) return true;
        if (!prevStep.inShoppingList) return false;

        return true;
    };

    const buildFormDataObjectFromMakeModel = (
        item: IMake_SELL | IModel_SELL
    ) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.nameEn,
            nameAr: item.nameAr,
            inShoppingList: item.inShoppingList,
            id: item.id,
            selected: true,
            icon: '',
        };
        return formObject;
    };
    const buildFormDataObjectFromYear = (item: IYear_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.year,
            nameAr: item.year,
            inShoppingList: item.inShoppingList,
            id: getModel().id,
            selected: true,
            icon: '',
        };
        return formObject;
    };
    const buildFormDataObjectFromKilometers = (item: IKilometrage_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.displayed,
            nameAr: item.displayed,
            inShoppingList: item.inShoppingList,
            id: getModel().id,
            selected: true,
            icon: '',
        };
        return formObject;
    };
    const buildFormDataObjectFromRegion = (item: IRegion_SELL) => {
        const formObject: ISmallArea = {
            id: item?.id,
            nameEn: item?.smallArea,
            nameAr: item.smallAreaAr,
            selected: true,
            showAlert: false,
            polygon: item.polygon,
            serviceable: item.serviceable,
            warehouse: item.warehouse,
        };
        return formObject;
    };

    const buildFormDataObjectFromTransmission = (item: ITransmission_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.transmission,
            nameAr: item.transmission,
            inShoppingList: item.inShoppingList,
            id: item.transmission,
            selected: true,
            icon: '',
        };
        return formObject;
    };

    const buildFormDataObjectFromTrafficPoint = (item: ITrafficPoint_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.trafficPoint,
            nameAr: item.trafficPoint,
            inShoppingList: item.inShoppingList,
            id: item.trafficPoint,
            selected: true,
            icon: '',
        };
        return formObject;
    };

    return {
        getMake,
        getModel,
        getRegion,
        getYear,
        getKilometers,
        getContactInfo,
        getPath,
        getTransmission,
        getTrafficPoint,
        updateMake,
        updateModel,
        updateRegion,
        updateYear,
        updateKilometers,
        updateTransmission,
        updateTraffficPoint,
        updateContactInfo,
        updatePath,
        setStepperStep,
        getStepperLastActiveStep,
        getTotalStepperSteps,
        getOtherInfoFlag,
        disableRegion,
        stepper,
        currentStep,
        getTransmissionOptionsFromYear,
        getTrafficPointOptions,
        getOtherInfoLabel,
        formatMakesForDisplay,
        formatModelsForDisplay,
        setChoosenData,
        getYearsKmFromTargetModel,
        getKmFromTargetModel,
        formatRegionsForDisplay,
        setChoosenDataRegion,
        setSellerContactInfo,
        getCurrentActiveStep,
        buildLeadObject,
        buildPerspectiveLeadObject,
        goBackToPreviousStep,
        clearForm,
        getDisclaimerText,
        shouldShowAlert,
        buildFormDataObjectFromMakeModel,
        buildFormDataObjectFromYear,
        buildFormDataObjectFromKilometers,
        buildFormDataObjectFromRegion,
        buildFormDataObjectFromTransmission,
        buildFormDataObjectFromTrafficPoint,
        getAddContactTime,
        triggerSpecificContactTimeEvent,
        getContactTimeUsed,
        getContactTimeObject,
        setContactTimeUsed,
        setContactTimeObject,
        enableSubmitButton,
        regionShowCheck,
        getContactStep,
    };
};

export default useSellCar;
