'use client';
import { useEffect } from 'react';
import { useTranslation } from '@/hook';
import { useDispatch } from 'react-redux';
import {
  nextStep,
  prevStep,
  setActionBtnDisabled,
  setActiveStep,
  setBookingSteps,
  setSelectedDay,
  setSelectedTime,
  setToaster,
} from '@/redux/reducers/checkoutReducer';
import { useAppSelector } from './redux-hooks';
import { useValidateTimeSlotMutation } from './retail-api-slice';
import { useRouter } from 'next/navigation';

const useStepper = () => {
  const { activeStep, selectedTime, bookingSteps, selectedCar, reservationType } = useAppSelector(
    (state) => state.checkout,
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation();
  const [validateSlot, { data: timeSlotValidated, error: timeSlotValidatedError }] =
    useValidateTimeSlotMutation();

  useEffect(() => {
    if (timeSlotValidated?.success) {
      dispatch(setActionBtnDisabled(true));
      dispatch(nextStep());
    }
  }, [timeSlotValidated]);

  useEffect(() => {
    if (timeSlotValidatedError) {
      const status = 'status' in timeSlotValidatedError ? timeSlotValidatedError.status : null;
      const message = 'message' in timeSlotValidatedError ? timeSlotValidatedError.message : null;

      if (status === 400) {
        dispatch(
          setToaster({
            flag: true,
            message: t('This time slot is no longer available'),
            type: false,
          }),
        );
      } else {
        dispatch(
          setToaster({
            flag: true,
            message: message
              ? t(message as unknown as TemplateStringsArray)
              : t('something went wrong'),
            type: false,
          }),
        );
      }
    }
  }, [timeSlotValidatedError]);

  useEffect(() => {
    if (bookingSteps[activeStep]?.id === 0 && selectedTime) {
      dispatch(setActionBtnDisabled(false));
    } else if (bookingSteps[activeStep]?.id === 0 && !selectedTime) {
      dispatch(setActionBtnDisabled(true));
    }
  }, [selectedTime]);

  useEffect(() => {
    if (bookingSteps[activeStep]?.id === 2 && reservationType !== null) {
      dispatch(setActionBtnDisabled(false));
    } else if (bookingSteps[activeStep]?.id === 2 && reservationType === null) {
      dispatch(setActionBtnDisabled(true));
    }
  }, [reservationType]);

  const translateNumbers = (phoneNumber: string | any) => {
    let str = phoneNumber;
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        str = str.replace(arabicNumbers[i], i);
      }
      return str;
    }
  };

  const resetStepper = () => {
    const newBookingSteps = [...bookingSteps];
    newBookingSteps.forEach((step, index) => {
      newBookingSteps[index] = {
        ...newBookingSteps[index],
        completed: false,
      };
    });
    dispatch(setBookingSteps(newBookingSteps));
    dispatch(setActiveStep(0));
  };

  const movingForward = () => {
    switch (bookingSteps[activeStep]?.id) {
      case 0:
        dispatch(setActionBtnDisabled(true));
        validateSlot(selectedTime);

        break;

      case 1:
        dispatch(nextStep());

        break;

      case 2:
        dispatch(setSelectedDay(null));
        dispatch(setSelectedTime(null));
        dispatch(setActionBtnDisabled(true));
        dispatch(nextStep());
        break;

      case 3:
        dispatch(setActionBtnDisabled(true));
        dispatch(nextStep());
        break;
      default:
        break;
    }
  };

  const movingBackward = (isMobileView: boolean) => {
    if (activeStep === 0) {
      router.back();
    } else {
      dispatch(setActionBtnDisabled(false));
      dispatch(prevStep());
    }
  };

  return {
    movingForward,
    movingBackward,
    resetStepper,
    translateNumbers,
  };
};

export default useStepper;
