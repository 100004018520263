'use client';
import { useAnalytics } from '@/integrations';
import {
    EInspectionType,
    EInspectionTypeSelectionVariants,
    IContanctInfo_SELL,
    IKilometrage_SELL,
    ILeadRequest,
    ILocationDropDown,
    IMake_SELL,
    IMakesApi_SELL,
    IModel_SELL,
    IModelApi_SELL,
    IPerspectiveLeadRequest,
    IReceiptForSellForm,
    IRegion_SELL,
    ISellCarForm,
    ISmallArea,
    ISpecificContactTime,
    IStepper_SELL_NEW,
    IStepperIntializer,
    IStepperItem_SELL_NEW,
    IStepValues,
    ITrafficPoint_SELL,
    ITransmission_SELL,
    IYear_SELL,
    IYearsKm_SELL,
    Region,
    StepperStep,
    TFormItemKey,
    TInspectionType,
    TSellFormDataItems,
    TStepperPath,
} from '@/types';
import { useTranslation } from '@/hook';
import useWindowDimensions, {
    DimensionsTargets,
} from '../use-window-width.hook';
import useSellFormData from './use-sell-form-data.hook';
import useSellStepper from './use-sell-stepper.hook';
import {
    CalendarIconGreen,
    CalendarIconOrange,
    ContactIconGreen,
    ContactIconOrange,
    KMIconGreen,
    KMIconOrange,
    LensGreenIcon,
    LensOrangeIcon,
    LocationIconGreen,
    LocationIconOrange,
    MakeIconGreen,
    MakeIconOrange,
    ModelIconGreen,
    ModelIconOrange,
    otherInfoIcon,
    otherInfoIconOrange,
} from '../../../public/Images';

import {
    DESKTOP_SCREEN_LABEL,
    FEATURE_FLAG_WAREHOUSE_INSPECTION_SELECTION_NEW_PAGE,
    MOBILE_SCREEN_LABEL,
} from '@/constants';
import { useRouter } from 'next/navigation';
import Logger from '@/utils/logger';
import { useFeatureValue } from '@growthbook/growthbook-react';

interface IFormatedData_SELL {
    selected: boolean;
    inShoppingList: boolean;
    icon: string;
    nameAr: string;
    nameEn: string;
    id: string;
}

interface IAnalyticsObject {
    make?: string;
    model?: string;
    year?: string;
    kilometers?: string;
    area?: string;
    zone?: string;
    name?: string;
    mobileNumber?: string;
    email?: string;
    source?: string;
    lang?: string;
    available?: boolean;
    transmission?: string;
    trafficPoint?: string;
    pinLocationUsed?: boolean;
    lead_id?: string;

    // eslint-disable-next-line no-unused-vars
    addMake(make: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addModel(model: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addYear(year: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addKilometers(kilometers: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addZone(zone: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addArea(area: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addMobileNumber(mobileNumber: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addName(name: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addEmail(email: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addAvailable(available: boolean): IAnalyticsObject;

    addPinLocationUsed(pinLocationUsed: boolean): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addSourceScreen(source: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addLang(lang: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addTransmission(transmission: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addTrafficPoint(trafficPoint: string): IAnalyticsObject;

    // eslint-disable-next-line no-unused-vars
    addLeadId(leadId: string): IAnalyticsObject;
}

type TFormatedDataItems_SELL =
    | IRegion_SELL
    | IContanctInfo_SELL
    | IFormatedData_SELL;

const useSellCarNew = () => {
    const { trackEvent } = useAnalytics();
    const { t, i18n } = useTranslation();
    const { width } = useWindowDimensions(DimensionsTargets.DESKTOP);
    const router = useRouter();
    const IS_DESKTOP = width > 1100;
    const screenSource = IS_DESKTOP
        ? DESKTOP_SCREEN_LABEL
        : MOBILE_SCREEN_LABEL;
    const IS_RTL = i18n.language === 'ar';
    const showDifferentaition = true;
    // const showNewMap = false;
    // const showNewMap = useFeatureIsOn(FEATURE_FLAG_MAP_REVAMP_A_B);
    const showNewMap = true;

    // if (APP_ENV === 'production') {
    //     // eslint-disable-next-line react-hooks/rules-of-hooks
    //     showNewMap = false;
    // } else {
    //     // eslint-disable-next-line react-hooks/rules-of-hooks
    //     const differentaitionFlag = useExperiment('oj58skj2RDqcEVQ-qyJTgw');
    //     showNewMap = differentaitionFlag === '1';
    // }

    const hideAllAlerts = true;
    const selectWarehouseInspection = useFeatureValue(
        FEATURE_FLAG_WAREHOUSE_INSPECTION_SELECTION_NEW_PAGE,
        EInspectionTypeSelectionVariants.ORIGINAL
    );

    const {
        intializeStepperSteps,
        goToSpecificStep,
        goToPreviousStep,
        resetStepper,
        getStepData,
        updateStepperWhenAStepIsFilledChainer,
        getCurrentActiveStep,
        getStepper,
        getPath: getPathStepper,
        updatePath,
        changeUrlBasedOnCurrentStep,
        updateStepper,
        manuallyDisableStep,
        manuallyEnableStep,
    } = useSellStepper();
    const {
        intializeFormData,
        updateFormDataItemsReducer,
        resetFormDataObjects,
        getFormItemValue,
    } = useSellFormData();

    const stepperIcons: Record<
        string,
        { iconGreen: string; iconOrange: string }
    > = {
        make: {
            iconGreen: MakeIconGreen,
            iconOrange: MakeIconOrange,
        },
        model: {
            iconGreen: ModelIconGreen,
            iconOrange: ModelIconOrange,
        },
        year: {
            iconGreen: CalendarIconGreen,
            iconOrange: CalendarIconOrange,
        },
        region: {
            iconGreen: LocationIconGreen,
            iconOrange: LocationIconOrange,
        },
        kilometers: {
            iconGreen: KMIconGreen,
            iconOrange: KMIconOrange,
        },
        otherinfo: {
            iconGreen: otherInfoIcon,
            iconOrange: otherInfoIconOrange,
        },
        contactInfo: {
            iconGreen: ContactIconGreen,
            iconOrange: ContactIconOrange,
        },
        inspectionType: {
            iconGreen: LensGreenIcon,
            iconOrange: LensOrangeIcon,
        },
    };

    const getExperimentFlag = () => {
        return showDifferentaition;
    };
    const getShowNewMap = () => {
        return showNewMap;
    };

    const intializeSellCar = (
        stepperSteps: IStepperIntializer[],
        formData: ISellCarForm,
        forceInitialize = false
    ) => {
        intializeStepperSteps(stepperSteps);
        intializeFormData(formData, forceInitialize);
    };

    // _____________________STEPPER MUTATIONS_____________________
    const disabelStepManually = (
        step: StepperStep,
        newStepper?: IStepperItem_SELL_NEW[]
    ) => {
        const stepper = getStepper();
        const toBeMutatedStepper = newStepper ? newStepper : stepper;
        const modifiedStepper = manuallyDisableStep(step, toBeMutatedStepper);

        updateStepper(modifiedStepper);
    };
    const enableStepManually = (
        step: StepperStep,
        newStepper?: IStepperItem_SELL_NEW[]
    ) => {
        const stepper = getStepper();
        const toBeMutatedStepper = newStepper ? newStepper : stepper;
        const modifiedStepper = manuallyEnableStep(step, toBeMutatedStepper);

        updateStepper(modifiedStepper);
    };
    const updateStepperBridge = (
        key: TFormItemKey,
        item: TFormatedDataItems_SELL,
        path: TStepperPath
    ) => {
        if (key === 'inspectionType') {
            const { nameEn, nameAr, id } = item as IFormatedData_SELL;
            const stepperObj: IStepValues = {
                value: nameEn,
                valueAr: nameAr,
                inShoppingList: getPath() === 'path1',
                backgroundColor: '#e6fbf2',
            };
            if (width <= 1100) {
                const newStepper = updateStepperWhenAStepIsFilledChainer(
                    key,
                    stepperObj,
                    path
                );
                if (id === EInspectionType.HOME)
                    enableStepManually('region', newStepper);
                if (id === EInspectionType.WAREHOUSE)
                    disabelStepManually('region', newStepper);
                return;
            }
            if (id === EInspectionType.HOME) enableStepManually('region');
            if (id === EInspectionType.WAREHOUSE) disabelStepManually('region');
            return;
        }
        if (key === 'contactInfo') {
            const { phone } = item as IContanctInfo_SELL;
            const stepperObj: IStepValues = {
                value: phone,
                valueAr: phone,
                inShoppingList: getPath() === 'path1',

                backgroundColor: '#e6fbf2',
            };

            updateStepperWhenAStepIsFilledChainer(key, stepperObj, path);
            return;
        }
        if (key === 'region') {
            const {
                smallArea,
                smallAreaAr,
                addressCity,
                serviceable,
                warehouse,
            } = item as IRegion_SELL;
            // const valueToBeAdded = smallArea ? (IS_RTL ? smallAreaAr : smallArea) : addressCity;
            const stepperObj: IStepValues = {
                value: smallArea ? smallArea : addressCity,
                valueAr: smallArea ? smallAreaAr : addressCity,
                inShoppingList: serviceable || warehouse,

                backgroundColor: '#e6fbf2',
            };

            updateStepperWhenAStepIsFilledChainer(key, stepperObj, path);
            return;
        }
        if (key === 'trafficPoint' || key === 'transmission') {
            const { nameEn, inShoppingList, icon } = item as IFormatedData_SELL;
            const objectToBeSent =
                key === 'trafficPoint'
                    ? { trafficPoint: nameEn }
                    : { transmission: nameEn };

            const keyValue = 'otherinfo';
            const stepperObj: IStepValues = {
                value: getOtherInfoLabel(objectToBeSent),
                valueAr: getOtherInfoLabel(objectToBeSent),
                inShoppingList: inShoppingList,
                iconValue: icon,
                backgroundColor: '#e6fbf2',
            };

            const trafficPoint = nameEn;
            const transmission = getTransmission();

            if (
                trafficPoint !== '' &&
                transmission.transmission !== '' &&
                key === 'trafficPoint'
            )
                updateStepperWhenAStepIsFilledChainer(
                    keyValue,
                    stepperObj,
                    path
                );
            return;
        }
        const { nameEn, nameAr, inShoppingList, icon } =
            item as IFormatedData_SELL;
        const stepperObj: IStepValues = {
            value: nameEn,
            valueAr: nameAr,
            inShoppingList: inShoppingList,
            iconValue: icon,
            backgroundColor: '#e6fbf2',
        };
        updateStepperWhenAStepIsFilledChainer(key, stepperObj, path);
    };
    const updateStepperPath = (
        key: TFormItemKey,
        item: TFormatedDataItems_SELL
    ) => {
        if (key === 'contactInfo') return getPath();
        if (key === 'inspectionType') {
            const { id } = item as IFormatedData_SELL;
            if ((id as TInspectionType) === 'HomeInspection') return getPath();
            const stepper = getStepper();
            let path: TStepperPath = 'path1';
            stepper.map(step => {
                if (
                    step.label !== 'region' &&
                    step.label !== 'contactInfo' &&
                    step.value
                ) {
                    path = step.inShoppingList ? 'path1' : 'path2';
                }
            });
            updatePath(path);
            return path;
        }
        if (key === 'region') {
            const { serviceable, warehouse } = item as IRegion_SELL;
            const path = serviceable || warehouse ? 'path1' : 'path2';
            updatePath(path);
            return path;
        }
        const { inShoppingList } = item as IFormatedData_SELL;
        const path = inShoppingList ? 'path1' : 'path2';

        updatePath(path);
        return path;
    };
    const goBackToPreviousStep = () => {
        const cuurrentStep = getCurrentActiveStep();
        const prevStep = goToPreviousStep(cuurrentStep.label);
        if (!prevStep) {
            router.replace('sell-car');
        }
        if (prevStep) changeUrlBasedOnCurrentStep(prevStep.label);
    };
    const goToTargetStep = (targetStep: StepperStep) => {
        const targetStp = goToSpecificStep(targetStep);
        if (targetStp) changeUrlBasedOnCurrentStep(targetStp.label);
    };

    const returnAllowedStepsLength = () => {
        const stepper = getStepper();
        const allowedSteps = stepper.filter(
            stp => stp.manuallyDisabled === false
        );
        return allowedSteps.length;
    };

    const getStpecificStep = (step: StepperStep) => {
        return getStepData(step);
    };

    const getFormRecieptForThankYouPage = (): IReceiptForSellForm[] => {
        const stepper = getStepper();
        const region = getRegion();
        const regionOutofCoverageButCarInShoopingLIst =
            region.address !== '' && !(region.serviceable || region.warehouse);

        const inspectionType = getInspectionType();

        const path = getPath();
        const isPath1 =
            path === 'path1' || regionOutofCoverageButCarInShoopingLIst;
        const receipt = stepper
            .map(stp => {
                if (!isPath1 && stp.label === 'inspectionType') {
                    return {
                        label: stp.label,
                        value: '',
                        iconValue: stp.iconValue,
                        icon: isPath1
                            ? stepperIcons[stp.label].iconGreen
                            : stepperIcons[stp.label].iconOrange,
                    };
                }
                return {
                    label: stp.label,
                    value: IS_RTL ? stp.valueAr : stp.value,
                    iconValue: stp.iconValue,
                    icon: isPath1
                        ? stepperIcons[stp.label].iconGreen
                        : stepperIcons[stp.label].iconOrange,
                };
            })
            .filter(stp =>
                inspectionType === EInspectionType.WAREHOUSE &&
                stp.label === 'region'
                    ? false
                    : true
            );
        if (getExperimentFlag() && width > 1100) {
            const inspectionReceiptObj = {
                label: 'inspectionType' as keyof IStepper_SELL_NEW,
                value: t(inspectionType),
                iconValue: '',
                icon: isPath1
                    ? stepperIcons.inspectionType?.iconGreen
                    : stepperIcons.inspectionType.iconOrange,
            };
            receipt.unshift(inspectionReceiptObj);
        }

        return receipt;
    };
    // _____________________STEPPER MUTATIONS END_____________________

    // _____________________ANALYTICS_____________________
    const createAnalyticsBuilderObj = () => {
        const analyticsObj: IAnalyticsObject = {
            addMake: function (make: string) {
                analyticsObj.make = make;
                return analyticsObj;
            },
            addModel: function (model: string) {
                analyticsObj.model = model;
                return analyticsObj;
            },
            addYear: function (year: string) {
                analyticsObj.year = year;
                return analyticsObj;
            },
            addKilometers: function (kilometers: string) {
                analyticsObj.kilometers = kilometers;
                return analyticsObj;
            },
            addZone: function (zone: string) {
                analyticsObj.zone = zone;
                return analyticsObj;
            },
            addArea: function (area: string) {
                analyticsObj.area = area;
                return analyticsObj;
            },
            addMobileNumber: function (mobileNumber: string) {
                analyticsObj.mobileNumber = mobileNumber;
                return analyticsObj;
            },
            addName: function (name: string) {
                analyticsObj.name = name;
                return analyticsObj;
            },
            addEmail: function (email: string) {
                analyticsObj.email = email;
                return analyticsObj;
            },

            addTransmission: function (transmission: string) {
                analyticsObj.transmission = transmission;
                return analyticsObj;
            },
            addTrafficPoint: function (trafficPoint: string) {
                analyticsObj.trafficPoint = trafficPoint;
                return analyticsObj;
            },
            addAvailable: function (available: boolean) {
                analyticsObj.available = available;
                return analyticsObj;
            },
            addPinLocationUsed: function (pinLocationUsed: boolean) {
                analyticsObj.pinLocationUsed = pinLocationUsed;
                return analyticsObj;
            },

            addLang: function (lang: string) {
                analyticsObj.lang = lang;
                return analyticsObj;
            },

            addSourceScreen: function (sourceScreen: string) {
                analyticsObj.source = sourceScreen;
                return analyticsObj;
            },
            addLeadId: function (leadId: string) {
                analyticsObj.lead_id = leadId;
                return analyticsObj;
            },
        };
        return analyticsObj;
    };
    const triggerAnalytics = (step: TFormItemKey, item: IFormatedData_SELL) => {
        if (step === 'make') {
            const analyticsObj = createAnalyticsBuilderObj()
                .addMake(item.nameEn)
                .addAvailable(item.inShoppingList);
            trackEvent('Sell_Make', analyticsObj);
        }
        if (step === 'model') {
            const analyticsObj = createAnalyticsBuilderObj()
                .addMake(getMake().nameEn)
                .addModel(item.nameEn)
                .addAvailable(item.inShoppingList);
            trackEvent('Sell_Model', analyticsObj);
        }

        if (step === 'year') {
            const analyticsObj = createAnalyticsBuilderObj()
                .addMake(getMake().nameEn)
                .addModel(getModel().nameEn)
                .addYear(item.nameEn)
                .addAvailable(item.inShoppingList);
            trackEvent('Sell_Year', analyticsObj);
        }
        if (step === 'kilometers') {
            const analyticsObj = createAnalyticsBuilderObj()
                .addMake(getMake().nameEn)
                .addModel(getModel().nameEn)
                .addYear(getYear().year)
                .addKilometers(item.nameEn.replace(/,/g, '').split(' - ')[1])
                .addAvailable(item.inShoppingList);
            trackEvent('Sell_Kilometers', analyticsObj);
        }
        if (step === 'transmission') {
            const analyticsObj = createAnalyticsBuilderObj()
                .addMake(getMake().nameEn)
                .addModel(getModel().nameEn)
                .addYear(getYear().year)
                .addKilometers(
                    getKilometers().displayed.replace(/,/g, '').split(' - ')[1]
                )
                .addLang(i18n.language)
                .addSourceScreen(screenSource)
                .addTransmission(item.nameEn);
            trackEvent('Sell_Transmission', analyticsObj);
        }
        if (step === 'trafficPoint') {
            const analyticsObj = createAnalyticsBuilderObj()
                .addMake(getMake().nameEn)
                .addModel(getModel().nameEn)
                .addYear(getYear().year)
                .addKilometers(
                    getKilometers().displayed.replace(/,/g, '').split(' - ')[1]
                )
                .addLang(i18n.language)
                .addSourceScreen(screenSource)
                .addTrafficPoint(item.nameEn);

            trackEvent('Sell_Traffic_Point', analyticsObj);
        }
        if (step === 'inspectionType') {
            const event =
                item.id === EInspectionType.WAREHOUSE
                    ? 'Sell_Warehouse_Inspection'
                    : 'Sell_Home_Inspection';
            const analyticsObj = createAnalyticsBuilderObj()
                .addMake(getMake().nameEn)
                .addModel(getModel().nameEn)
                .addYear(getYear().year)
                .addKilometers(
                    getKilometers().displayed.replace(/,/g, '').split(' - ')[1]
                )
                .addLang(i18n.language)
                .addSourceScreen(screenSource)
                .addArea(getRegion().smallArea);

            trackEvent(event, analyticsObj);
        }
    };
    const triggerDefaultInspectionEvent = (item: IFormatedData_SELL) => {
        const event =
            item.id === EInspectionType.WAREHOUSE
                ? 'Sell_Warehouse_Inspection'
                : 'Sell_Home_Inspection';
        const analyticsObj = createAnalyticsBuilderObj()
            .addMake(getMake().nameEn)
            .addModel(getModel().nameEn)
            .addYear(getYear().year)
            .addKilometers(
                getKilometers().displayed.replace(/,/g, '').split(' - ')[1]
            )
            .addLang(i18n.language)
            .addSourceScreen(screenSource)
            .addArea(getRegion().smallArea);

        trackEvent(event, analyticsObj);
    };

    const triggerEventRegion = (item: IRegion_SELL) => {
        const pinLocationUsed = item.address !== '';
        const analyticsObj = createAnalyticsBuilderObj()
            .addMake(getMake().nameEn)
            .addModel(getModel().nameEn)
            .addYear(getYear().year)
            .addKilometers(getKilometers().value.toString())
            .addArea(item.bigArea)
            .addZone(item.smallArea)
            .addPinLocationUsed(pinLocationUsed)
            .addAvailable(item.serviceable);

        trackEvent('Sell_Region', analyticsObj);
    };

    const triggerEventContactInfo = (item: IContanctInfo_SELL) => {
        const regionObj = getRegion();
        const isPath1 = getPath() === 'path1';
        const analyticsObj = createAnalyticsBuilderObj()
            .addMake(getMake().nameEn)
            .addModel(getModel().nameEn)
            .addYear(getYear().year)
            .addKilometers(getKilometers().value.toString())
            .addArea(regionObj.bigArea)
            .addZone(regionObj.smallArea)
            .addEmail(item.email)
            .addName(item.name)
            .addMobileNumber(item.phone)
            .addAvailable(isPath1);

        trackEvent('Sell_Personal_Details', analyticsObj);
    };

    const triggerVerificationEvent = (
        item: IContanctInfo_SELL,
        leadId: string
    ) => {
        const regionObj = getRegion();
        const isPath1 = getPath() === 'path1';
        const analyticsObj = createAnalyticsBuilderObj()
            .addMake(getMake().nameEn)
            .addModel(getModel().nameEn)
            .addYear(getYear().year)
            .addKilometers(getKilometers().value.toString())
            .addArea(regionObj.bigArea)
            .addZone(regionObj.smallArea)
            .addEmail(item.email)
            .addName(item.name)
            .addMobileNumber(item.phone)
            .addAvailable(isPath1)
            .addLeadId(leadId);
        trackEvent('Sell_Car_Submitted', analyticsObj);
    };
    // const triggerSpecificContactTimeEvent = (
    //     timeObject: ISpecificContactTime
    // ) => {
    //     trackEvent('Sell_Car_Specific_Time_Selected', {
    //         date: timeObject.date,
    //         from: timeObject.from,
    //         to: timeObject.to,
    //         fromTo: timeObject.fromTo,
    //     });
    // };
    const triggerVerificationEventPath1 = (
        item: IContanctInfo_SELL,
        leadId: string
    ) => {
        const regionObj = getRegion();
        const isPath1 = getPath() === 'path1';
        const analyticsObj = createAnalyticsBuilderObj()
            .addMake(getMake().nameEn)
            .addModel(getModel().nameEn)
            .addYear(getYear().year)
            .addKilometers(getKilometers().value.toString())
            .addArea(regionObj.bigArea)
            .addZone(regionObj.smallArea)
            .addEmail(item.email)
            .addName(item.name)
            .addMobileNumber(item.phone)
            .addAvailable(isPath1)
            .addLeadId(leadId);
        trackEvent('Sell_Car_Submitted_Path_1', analyticsObj);
    };
    const triggerVerificationEventPath2 = (
        item: IContanctInfo_SELL,
        leadId: string
    ) => {
        const regionObj = getRegion();
        const isPath1 = getPath() === 'path1';
        const analyticsObj = createAnalyticsBuilderObj()
            .addMake(getMake().nameEn)
            .addModel(getModel().nameEn)
            .addYear(getYear().year)
            .addKilometers(getKilometers().value.toString())
            .addArea(regionObj.bigArea)
            .addZone(regionObj.smallArea)
            .addEmail(item.email)
            .addName(item.name)
            .addMobileNumber(item.phone)
            .addAvailable(isPath1)
            .addLeadId(leadId);
        trackEvent('Sell_Car_Submitted_Path_2', analyticsObj);
    };
    // _____________________ANALYTICS END_____________________

    // _____________________GET FORM DATA_____________________
    const getMake = (): IMake_SELL => {
        return getFormItemValue('make') as IMake_SELL;
    };
    const getModel = (): IModel_SELL => {
        return getFormItemValue('model') as IModel_SELL;
    };
    const getRegion = (): IRegion_SELL => {
        return getFormItemValue('region') as IRegion_SELL;
    };
    const getYear = (): IYear_SELL => {
        return getFormItemValue('year') as IYear_SELL;
    };
    const getKilometers = (): IKilometrage_SELL => {
        return getFormItemValue('kilometers') as IKilometrage_SELL;
    };
    const getContactInfo = (): IContanctInfo_SELL => {
        return getFormItemValue('contactInfo') as IContanctInfo_SELL;
    };
    const getPath = () => {
        return getPathStepper();
    };
    const getTransmission = (): ITransmission_SELL => {
        return getFormItemValue('transmission') as ITransmission_SELL;
    };
    const getTrafficPoint = (): ITrafficPoint_SELL => {
        return getFormItemValue('trafficPoint') as ITrafficPoint_SELL;
    };
    const getInspectionType = (): EInspectionType => {
        return getFormItemValue('inspectionType') as EInspectionType;
    };
    // _____________________GET FORM DATA END_____________________

    // _____________________UPDATE FORM DATA_____________________
    const updateFormData = (
        key: TFormItemKey,
        value: TSellFormDataItems,
        doNotClearSubsequentData?: boolean
    ) => {
        updateFormDataItemsReducer(key, value, doNotClearSubsequentData);
    };

    // bridge function between the UI interface and data representation
    const buildFormObject = (
        key: TFormItemKey,
        item: TFormatedDataItems_SELL
    ) => {
        if (key === 'inspectionType') {
            const { id } = item as IFormatedData_SELL;
            const inspectionType: TInspectionType = id as TInspectionType;

            if (width > 1100) {
                updateFormData(key, inspectionType, true);
                return;
            }
            updateFormData(key, inspectionType);
            return;
        }
        if (key === 'make') {
            const { nameAr, nameEn, id, icon, inShoppingList } =
                item as IFormatedData_SELL;
            const makeObj: IMake_SELL = {
                nameAr: nameAr,
                nameEn: nameEn,
                id: id,
                image: icon,
                inShoppingList: inShoppingList,
            };
            updateFormData(key, makeObj);
            return;
        }

        if (key === 'model') {
            const { nameAr, nameEn, id, inShoppingList } =
                item as IFormatedData_SELL;
            const modelObj: IModel_SELL = {
                nameAr: nameAr,
                nameEn: nameEn,
                id: id,
                inShoppingList: inShoppingList,
            };
            updateFormData(key, modelObj);
            return;
        }

        if (key === 'year') {
            const { nameEn, inShoppingList } = item as IFormatedData_SELL;
            const year: IYear_SELL = {
                year: nameEn,
                inShoppingList: inShoppingList,
            };
            updateFormData(key, year);
            return;
        }

        if (key === 'kilometers') {
            const { nameEn, inShoppingList } = item as IFormatedData_SELL;
            const kilometersObj: IKilometrage_SELL = {
                value: parseFloat(nameEn.replace(/,/g, '').split(' - ')[1]),
                displayed: nameEn,
                inShoppingList: inShoppingList,
            };
            updateFormData(key, kilometersObj);
            return;
        }

        if (key === 'transmission') {
            const { nameEn, inShoppingList, id } = item as IFormatedData_SELL;
            const transmissionObj: ITransmission_SELL = {
                transmission: nameEn,
                inShoppingList: inShoppingList,
                id: id,
            };
            updateFormData(key, transmissionObj);
            return;
        }
        if (key === 'trafficPoint') {
            const { nameEn, inShoppingList, id } = item as IFormatedData_SELL;
            const trafficPointObj: ITrafficPoint_SELL = {
                trafficPoint: nameEn,
                inShoppingList: inShoppingList,
                id: id,
            };
            updateFormData(key, trafficPointObj);
            return;
        }
        if (key === 'region') {
            const {
                id,
                bigArea,
                smallArea,
                name,
                smallAreaAr,
                bigAreaAr,
                address,
                coordinate,
                polygon,
                warehouse,
                serviceable,
                addressCity,
                sendPinLocation,
            } = item as IRegion_SELL;
            const regionObj: IRegion_SELL = {
                id: id,
                bigArea: bigArea,
                smallArea: smallArea,
                name: name,
                smallAreaAr: smallAreaAr,
                bigAreaAr: bigAreaAr,
                address: address,
                coordinate: coordinate,
                polygon: polygon,
                warehouse: warehouse,
                serviceable: serviceable,
                addressCity: addressCity,
                sendPinLocation: sendPinLocation,
            };
            updateFormData(key, regionObj);
        }
        if (key === 'contactInfo') {
            const { name, phone, email } = item as IContanctInfo_SELL;
            const contactInfoObj: IContanctInfo_SELL = {
                name: name,
                phone: phone,
                email: email,
            };
            updateFormData(key, contactInfoObj);
        }
    };
    // _____________________UPDATE FORM DATA END_____________________

    // ________________________________________FORMAT FETCHED DATA START______________________________________

    const sortDisplayedDataBasedOnShoppingList = (
        displayedArray: IFormatedData_SELL[]
    ) => {
        // sort function sorts an array in place
        displayedArray.sort((a, b) => {
            if (a.inShoppingList && !b.inShoppingList) {
                return -1; // a should come before b
            } else if (!a.inShoppingList && b.inShoppingList) {
                return 1; // b should come before a
            } else {
                return 0; // no sorting needed
            }
        });
    };

    const formatMakesForDisplay = (
        makes: IMakesApi_SELL[]
    ): IFormatedData_SELL[] => {
        if (!makes) return [];
        const currentSelectedMake = getMake();
        const formatedMakes: IFormatedData_SELL[] = makes?.map(make => {
            const diplayObj: IFormatedData_SELL = {
                selected: currentSelectedMake.nameEn === make.enName,
                icon: make.image,
                nameAr: make.arName,
                nameEn: make.enName,
                id: make.id,
                inShoppingList: make.inShoppingList,
            };
            return diplayObj;
        });
        sortDisplayedDataBasedOnShoppingList(formatedMakes);
        return formatedMakes;
    };
    const checkIfModelInShoppingList = (yearKm: IYearsKm_SELL[]) => {
        let isModelInShoppingList = false;
        yearKm.forEach(year => {
            if (year.inShoppingList) {
                isModelInShoppingList = true;
            }
        });
        return isModelInShoppingList;
    };

    const formatModelsForDisplay = (
        models: IModelApi_SELL[]
    ): IFormatedData_SELL[] => {
        const currentSelectedModel = getModel();
        const formatedModels: IFormatedData_SELL[] = models.map(model => {
            const inShoppingList = checkIfModelInShoppingList(model.yearsKm);
            const displayObj: IFormatedData_SELL = {
                selected: currentSelectedModel.nameEn === model.enName,
                icon: getMake().image,
                nameAr: model.arName,
                nameEn: model.enName,
                id: model.id,
                inShoppingList: inShoppingList,
            };
            return displayObj;
        });
        sortDisplayedDataBasedOnShoppingList(formatedModels);
        return formatedModels;
    };

    const formatYearsForDisplay = (
        model: IModelApi_SELL
    ): IFormatedData_SELL[] => {
        const formatedYears: IFormatedData_SELL[] = model.yearsKm.map(
            yearKm => {
                const displayObj: IFormatedData_SELL = {
                    selected: false,
                    icon: getMake().image,
                    nameAr: yearKm.year,
                    nameEn: yearKm.year,
                    id: yearKm.year,
                    inShoppingList: yearKm.inShoppingList,
                };
                return displayObj;
            }
        );
        formatedYears.sort(
            (a, b) => parseFloat(b.nameEn) - parseFloat(a.nameEn)
        );

        return formatedYears;
    };

    const getTransmissionOptionsFromYear = (
        yearData: IYear_SELL,
        models: IModelApi_SELL[]
    ) => {
        const year = yearData.year;
        const targetModel = models?.filter(mdl => mdl.id === getModel().id);
        const targetYearKm = targetModel[0]?.yearsKm.filter(
            yearKm => yearKm.year === year
        );

        const manualAccepted = targetYearKm[0]?.manualAccepted;
        const kilometers = getKilometers();
        const inShoppingList = kilometers?.inShoppingList;

        const formDataObjects: IFormatedData_SELL[] = [
            {
                selected: false,
                inShoppingList: inShoppingList,
                icon: '',
                nameAr: t('automatic'),
                nameEn: t('automatic'),
                id: 'automatic',
            },
            {
                selected: false,
                inShoppingList: manualAccepted && inShoppingList,
                icon: '',
                nameAr: t('manual'),
                nameEn: t('manual'),
                id: 'manual',
            },
        ];
        return formDataObjects;
    };

    const getTrafficPointOptions = (
        yearData: IYear_SELL,
        models: IModelApi_SELL[]
    ) => {
        const year = yearData.year;
        const targetModel = models?.filter(mdl => mdl.id === getModel().id);
        const targetYearKm = targetModel[0]?.yearsKm.filter(
            yearKm => yearKm.year === year
        );
        const transmissionInShoppingList = getTransmission()?.inShoppingList;
        const inShoppingList = targetYearKm[0]?.inShoppingList;
        const formDataObjects: IFormatedData_SELL[] = [
            {
                selected: false,
                inShoppingList: inShoppingList && transmissionInShoppingList,
                icon: '',
                nameAr: t('cairo'),
                nameEn: t('cairo'),
                id: 'cairo',
            },
            {
                selected: false,
                inShoppingList: inShoppingList && transmissionInShoppingList,
                icon: '',
                nameAr: t('giza'),
                nameEn: t('giza'),
                id: 'giza',
            },
            {
                selected: false,
                inShoppingList: inShoppingList && transmissionInShoppingList,
                icon: '',
                nameAr: t('others'),
                nameEn: t('others'),
                id: 'others',
            },
        ];
        return formDataObjects;
    };

    const getTargetModel = (
        model: IModel_SELL,
        models: IModelApi_SELL[]
    ): IModelApi_SELL => {
        const targetModel = models?.filter(mdl => mdl.id === model.id);
        return targetModel[0];
    };

    const getYearsKmFromTargetModel = (allModels: IModelApi_SELL[]) => {
        const selectedModel = getModel();

        const targetFetchedModel = getTargetModel(selectedModel, allModels);
        return formatYearsForDisplay(targetFetchedModel);
    };
    const generateKilometrages = (defaultMaxKM = '130000') => {
        const maxKM = +'220000';
        let start = 0;
        let end = 9999;
        const step = 10000;
        const result = [];
        while (end + 1 < maxKM) {
            result.push({
                original: `${start}-${end}`,
                formatted: `${start.toLocaleString()} - ${end.toLocaleString()}`,
                stepperDisplay: `${start.toLocaleString()}-${end.toLocaleString()}`,
                inShoppingList: end < +defaultMaxKM && end > 19999,
            });
            start += step;
            end += step;
        }
        if (start < maxKM) {
            result.push({
                original: `${start}-${maxKM}`,
                formatted: `${start.toLocaleString()} - ${maxKM.toLocaleString()}`,
                stepperDisplay: `${start.toLocaleString()}-${maxKM.toLocaleString()}`,
                inShoppingList: end < +defaultMaxKM && maxKM > 19999,
            });
        }

        return result;
    };
    const formatKilometersForDisplay = (
        yearKm: IYearsKm_SELL
    ): IFormatedData_SELL[] => {
        const generatedKilometers = generateKilometrages(yearKm.km);
        const selectedYear = getYear();
        const formatedKm = generatedKilometers.map(km => {
            const displayObj: IFormatedData_SELL = {
                selected: false,
                icon: getMake().image,
                nameAr: km.formatted,
                nameEn: km.formatted,
                id: km.original,
                inShoppingList: selectedYear?.inShoppingList
                    ? km.inShoppingList
                    : false,
            };
            return displayObj;
        });
        return formatedKm;
    };

    const getTargetYearKm = (
        model: IModel_SELL,
        year: string,
        models: IModelApi_SELL[]
    ): IYearsKm_SELL => {
        const targetModel = models?.filter(mdl => mdl.id === model.id);
        const targetYearKm = targetModel[0].yearsKm.filter(
            yearKm => yearKm.year === year
        );
        return targetYearKm[0];
    };
    const getKmFromTargetModel = (allModels: IModelApi_SELL[]) => {
        const selectedModel = getModel();
        const selectedYear = getYear();
        const targetFetchedModel = getTargetYearKm(
            selectedModel,
            selectedYear.year,
            allModels
        );
        return formatKilometersForDisplay(targetFetchedModel);
    };

    const formatRegionsForDisplay = (
        regions: Region[]
    ): ILocationDropDown[] => {
        const formatedLocations: ILocationDropDown[] = regions?.map(bigArea => {
            const smallAreas: ISmallArea[] = bigArea?.smallArea?.map(
                smallArea => {
                    const area: ISmallArea = {
                        id: smallArea.id,
                        nameEn: smallArea.name,
                        nameAr: smallArea.arName,
                        selected: false,
                        showAlert: false,
                        polygon: smallArea.polygon,
                        warehouse: smallArea.warehouse,
                        serviceable: smallArea.serviceable,
                    };
                    return area;
                }
            );
            const location: ILocationDropDown = {
                id: bigArea.id,
                bigAreaEn: bigArea.bigArea,
                bigAreaAr: bigArea.arBigArea,
                expanded: false,
                smallArea: [...smallAreas],
            };
            return location;
        });
        return formatedLocations;
    };

    // used for desktop
    const enableSubmitButton = () => {
        if (typeof window === 'undefined') return;
        const stepperArr = getStepper();
        const isClient = window !== undefined;

        const enableSubmit = stepperArr.filter(step => {
            return (
                step.value === '' &&
                step.allowed &&
                ['contactInfo', 'inspectionType'].indexOf(step.label) === -1
            );
        });

        return isClient && enableSubmit.length === 0;
    };

    const disableRegion = () => {
        const regionStep = getStepData('region');
        return !(regionStep?.activated && regionStep.allowed);
    };

    const getOtherInfoLabel = ({
        transmission,
        trafficPoint,
    }: {
        transmission?: string;
        trafficPoint?: string;
    }) => {
        const transmissionValue = transmission
            ? transmission
            : getTransmission().transmission;
        const trafficPointValue = trafficPoint
            ? trafficPoint
            : getTrafficPoint().trafficPoint;

        // const transmissionString = transmission?.transmission ? transmission?.transmission : '';
        // const trafficPointString = trafficPoint.trafficPoint ? trafficPoint.trafficPoint : '';

        if (transmissionValue !== '' && trafficPointValue !== '') {
            return t(transmissionValue) + ', ' + t(trafficPointValue);
        } else {
            return t(transmissionValue) || t(trafficPointValue) || '';
        }
    };
    // ________________________________________FORMAT FETCHED DATA END______________________________________

    // ________________________________________BUILD FORM DATA OBJECT______________________________________
    const buildFormDataObjectFromMakeModel = (
        item: IMake_SELL | IModel_SELL
    ) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.nameEn,
            nameAr: item.nameAr,
            inShoppingList: item.inShoppingList,
            id: item.id,
            selected: true,
            icon: '',
        };
        return formObject;
    };
    const buildFormDataObjectFromYear = (item: IYear_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.year,
            nameAr: item.year,
            inShoppingList: item.inShoppingList,
            id: getModel().id,
            selected: true,
            icon: '',
        };
        return formObject;
    };
    const buildFormDataObjectFromKilometers = (item: IKilometrage_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.displayed,
            nameAr: item.displayed,
            inShoppingList: item.inShoppingList,
            id: getModel().id,
            selected: true,
            icon: '',
        };
        return formObject;
    };
    const buildFormDataObjectFromRegion = (item: IRegion_SELL) => {
        const formObject: ISmallArea = {
            id: item?.id,
            nameEn: item?.smallArea,
            nameAr: item.smallAreaAr,
            selected: true,
            showAlert: false,
            polygon: item.polygon,
            serviceable: item.serviceable,
            warehouse: item.warehouse,
        };
        return formObject;
    };

    const buildFormDataObjectFromTransmission = (item: ITransmission_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.transmission,
            nameAr: item.transmission,
            inShoppingList: item.inShoppingList,
            id: item.transmission,
            selected: true,
            icon: '',
        };
        return formObject;
    };

    const buildFormDataObjectFromTrafficPoint = (item: ITrafficPoint_SELL) => {
        const formObject: IFormatedData_SELL = {
            nameEn: item?.trafficPoint,
            nameAr: item.trafficPoint,
            inShoppingList: item.inShoppingList,
            id: item.trafficPoint,
            selected: true,
            icon: '',
        };
        return formObject;
    };

    const buildLeadObject = (
        otp: string,
        lang: string,
        userInfo: IContanctInfo_SELL,
        specificContactTime?: ISpecificContactTime
    ) => {
        const make = getMake();
        const model = getModel();
        const year = getYear();
        const kilometers = getKilometers();
        const region = getRegion();
        const transmission = getTransmission();
        const trafficPoint = getTrafficPoint();
        const inspectionType = getInspectionType();
        const regionExists = inspectionType === EInspectionType.HOME;

        const leadObj: ILeadRequest = {
            year: year.year,
            name: userInfo.name,
            make: {
                id: make.id,
                enName: make.nameEn,
            },
            model: {
                id: model.id,
                enName: model.nameEn,
            },
            transmission: transmission.id !== '' ? transmission?.id : 'NA',
            trafficUnit: trafficPoint.id !== '' ? trafficPoint.id : 'NA',
            region:
                regionExists &&
                (region.smallArea !== '' || region.addressCity !== '')
                    ? region.smallArea
                    : 'NA',
            regionObject:
                regionExists &&
                (region.smallArea !== '' || region.addressCity !== '')
                    ? {
                          _id: region.id,
                          bigArea: region.bigArea,
                          smallArea: region.smallArea,
                          name: region.smallArea,
                          address: region.address,
                          coordinate: region.sendPinLocation
                              ? region.coordinate
                              : null,
                          addressCity: region.addressCity,
                          serviceable: region.serviceable,
                          warehouse: region.warehouse,
                      }
                    : null,
            phone: userInfo.phone,
            phoneNumber: userInfo.phone,
            otp: otp,
            email: userInfo?.email,
            kilometers: kilometers.value,
            language: lang,
            specificContactTime: specificContactTime,
            inspectionType: inspectionType,
        };
        if (userInfo.userId) leadObj.userId = userInfo.userId;
        // if (addContactTime && specificContactTime && specificContactTime.date) {
        //   // triggerSpecificContactTimeEvent(specificContactTime);
        //   setContactTimeUsed(true);
        //   setContactTimeObject(specificContactTime);
        // }
        // triggerVerificationEvent(userInfo);
        return leadObj;
    };

    const buildPerspectiveLeadObject = (
        lang: string,
        userInfo: IContanctInfo_SELL
    ) => {
        const make = getMake();
        const model = getModel();
        const year = getYear();
        const kilometers = getKilometers();
        const region = getRegion();
        const transmission = getTransmission();
        const trafficPoint = getTrafficPoint();
        const inspectionType = getInspectionType();
        const regionExists = inspectionType === EInspectionType.HOME;

        const perspectiveLeadObj: IPerspectiveLeadRequest = {
            year: year.year,
            name: userInfo.name,
            make: {
                id: make.id,
                enName: make.nameEn,
            },
            model: {
                id: model.id,
                enName: model.nameEn,
            },
            transmission: transmission.id !== '' ? transmission?.id : 'NA',
            trafficUnit: trafficPoint.id !== '' ? trafficPoint.id : 'NA',
            regionObject:
                regionExists &&
                (region.smallArea !== '' || region.addressCity !== '')
                    ? {
                          _id: region.id,
                          bigArea: region.bigArea,
                          smallArea: region.smallArea,
                          name: region.smallArea,
                          address: region.address,
                          coordinate: region.sendPinLocation
                              ? region.coordinate
                              : null,
                          addressCity: region.addressCity,
                          serviceable: region.serviceable,
                          warehouse: region.warehouse,
                      }
                    : null,
            phone: userInfo.phone,
            phoneNumber: userInfo.phone,
            email: userInfo?.email,
            kilometers: kilometers.value,
            language: lang,
            inspectionType: inspectionType,
            userId: userInfo.userId,
        };
        // triggerVerificationEvent(userInfo);
        return perspectiveLeadObj;
    };
    // ________________________________________BUILD FORM DATA OBJECT END______________________________________

    // _____________________UPDATE FORM DATA_____________________
    const clearForm = () => {
        resetStepper();
        resetFormDataObjects();
    };

    const setChoosenData = (
        key: TFormItemKey,
        item: TFormatedDataItems_SELL
    ) => {
        // update path
        const path = updateStepperPath(key, item);
        // set form data
        Logger.info(getPath());
        buildFormObject(key, item);

        // update stepper
        updateStepperBridge(key, item, path as TStepperPath);

        // trigger analytics
        if (key === 'region') {
            triggerEventRegion(item as IRegion_SELL);
        } else if (key === 'contactInfo') {
            triggerEventContactInfo(item as IContanctInfo_SELL);
        } else {
            triggerAnalytics(key, item as IFormatedData_SELL);
        }
    };

    const getDisclaimerText = () => {
        if (hideAllAlerts) return '';
        const make = getMake();
        const model = getModel();
        const year = getYear();
        const kilometers = getKilometers();
        const region = getRegion();
        let disclaimerText = '';
        if (
            region.addressCity &&
            !region.name &&
            !region.warehouse &&
            !region.serviceable
        ) {
            disclaimerText = t('regionSellDisclaimerCase3', {
                location: region.addressCity,
            });
            return disclaimerText;
        }
        if (region.warehouse) {
            disclaimerText = t('regionSellDisclaimerCase2', {
                location: IS_RTL ? region.smallAreaAr : region.smallArea,
            });
            return disclaimerText;
        }
        if (getPath() === 'path1') return disclaimerText;
        if (!make.inShoppingList) {
            disclaimerText = t('makeSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
            });
            return disclaimerText;
        }
        if (!model.inShoppingList) {
            disclaimerText = t('modelSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
                model: IS_RTL ? model.nameAr : model.nameEn,
            });
            return disclaimerText;
        }
        if (!year.inShoppingList) {
            disclaimerText = t('yearSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
                model: IS_RTL ? model.nameAr : model.nameEn,
                year: year?.year,
            });
            return disclaimerText;
        }
        if (!kilometers.inShoppingList) {
            disclaimerText = t('kmSellDisclaimer', {
                make: IS_RTL ? make.nameAr : make.nameEn,
                model: IS_RTL ? model.nameAr : model.nameEn,
                km: kilometers?.displayed.split('-')[0],
            });
            return disclaimerText;
        }

        return disclaimerText;
    };

    //   const shouldShowAlert = (key: keyof IStepper_SELL) => {
    //     if (hideAllAlerts) return false;
    //     if (key === 'make') return true;

    //     const prevStepKey = getPreviousStep(key);
    //     const prevStep = getStepperStep(prevStepKey as keyof IStepper_SELL);

    //     if (prevStep.inShoppingList) return true;
    //     if (!prevStep.inShoppingList) return false;

    //     return true;
    //   };

    return {
        setChoosenData,
        clearForm,
        getMake,
        getModel,
        getRegion,
        getYear,
        getKilometers,
        getContactInfo,
        getTransmission,
        getTrafficPoint,
        getInspectionType,
        intializeSellCar,
        getPath,
        formatMakesForDisplay,
        formatModelsForDisplay,
        getTransmissionOptionsFromYear,
        getTrafficPointOptions,
        getYearsKmFromTargetModel,
        getKmFromTargetModel,
        formatRegionsForDisplay,
        buildFormDataObjectFromMakeModel,
        buildFormDataObjectFromYear,
        buildFormDataObjectFromKilometers,
        buildFormDataObjectFromTrafficPoint,
        buildFormDataObjectFromTransmission,
        buildFormDataObjectFromRegion,
        enableSubmitButton,
        disableRegion,
        getOtherInfoLabel,
        buildLeadObject,
        buildPerspectiveLeadObject,
        goBackToPreviousStep,
        getCurrentActiveStep,
        getStepper,
        goToTargetStep,
        returnAllowedStepsLength,
        getStpecificStep,
        getFormRecieptForThankYouPage,
        triggerVerificationEvent,
        triggerVerificationEventPath1,
        triggerVerificationEventPath2,
        getDisclaimerText,
        getExperimentFlag,
        getShowNewMap,
        triggerDefaultInspectionEvent,
        selectWarehouseInspection,
    };
};
export default useSellCarNew;
