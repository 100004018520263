import {
    BookingStep,
    bookingTypeInput,
    CarDetailsType,
    isReserveHomeAllowed,
    isReserveHubAllowed,
    isSeeHomeAllowed,
    isSeeHubAllowed,
    Toaster,
} from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Showroom, TimeSlot } from '@/types/Showroom.types';
import { CheckoutState } from '../../types/redux-state.types';
import {
    generalBookingSteps,
    generalHomeTestDirveBookingSteps,
    generalOnlyHomeBookingSteps,
    reserveOnlyShowroomBookingSteps,
    seeOnlyShowroomBookingSteps,
    seeShowroomBookingSteps,
} from '@/constants/BookingSteps';
import { Option } from '@/components/checkout/Dropdown';
// import i18n from '@/translations';

const initialState: CheckoutState = {
    bookingSteps: [],
    activeStep: 0,
    selectedDay: null,
    selectedTime: null,
    selectedCarId: undefined,
    selectedCar: null,
    isBookingReserved: false,
    reservationType: null,
    showroom: null,
    calendar: [],
    availableDaysCount: 0,
    availableSlotsCount: 0,
    toaster: {
        flag: false,
        message: '',
        type: false,
    },
    confirmedBookingData: null,
    actionBtnDisabled: true,
    showOTP: false,
    otpError: '',
    selectedArea: null,
    selectedCity: null,
    paymentSrc: '',
    building: null,
    landmark: null,
    street: null,
    checkoutSubmitBtnText: 'Continue',
    isLoading: false,
};

export const checkoutReducer = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        nextStep: state => {
            const { activeStep, bookingSteps } = state;
            const step = bookingSteps[activeStep];
            const completedStep = { ...step, completed: true };
            const newSteps = [...bookingSteps];
            newSteps[activeStep] = completedStep;
            state.activeStep = activeStep + 1;
            state.bookingSteps = newSteps;
        },

        prevStep: state => {
            const { activeStep, bookingSteps } = state;
            const step = bookingSteps[activeStep - 1];
            const completedStep = { ...step, completed: false };
            const newSteps = [...bookingSteps];
            newSteps[activeStep - 1] = completedStep;
            //REVISIT
            // if (activeStep === 1) newSteps[1].nextBtnText = i18n.t('get OTP');

            state.activeStep = activeStep - 1;
            state.bookingSteps = newSteps;
        },

        setSelectedDay: (state, action: PayloadAction<string | null>) => {
            state.selectedDay = action.payload;
        },

        setActiveStep: (state, action: PayloadAction<number>) => {
            state.activeStep = action.payload;
        },
        setSelectedTime: (state, action: PayloadAction<TimeSlot | null>) => {
            state.selectedTime = action.payload;
        },

        setSelectedCarID: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedCarId = action.payload;
        },

        setBookingType: (state, action: PayloadAction<bookingTypeInput>) => {
            const { bookingType, availableBookingTypes } = action.payload;
            state.isBookingReserved = bookingType;
            if (bookingType) {
                if (
                    isReserveHomeAllowed(availableBookingTypes) &&
                    isReserveHubAllowed(availableBookingTypes)
                )
                    state.bookingSteps = generalBookingSteps;
                else if (
                    isReserveHubAllowed(availableBookingTypes)
                )
                    state.bookingSteps = reserveOnlyShowroomBookingSteps;
                else if (
                    isReserveHomeAllowed(availableBookingTypes)
                )
                    state.bookingSteps = generalOnlyHomeBookingSteps;
            } else {
                if (
                    isSeeHomeAllowed(availableBookingTypes) &&
                    isSeeHubAllowed(availableBookingTypes)
                )
                    state.bookingSteps = generalBookingSteps;
                else if (
                    isSeeHubAllowed(availableBookingTypes)
                )
                    state.bookingSteps = seeOnlyShowroomBookingSteps;
                else if (
                    isSeeHomeAllowed(availableBookingTypes)
                )
                    state.bookingSteps = generalOnlyHomeBookingSteps;
            }
        },
        setResevationType: (state, action: PayloadAction<null | number>) => {
            state.reservationType = action.payload;
            if (action.payload === 1)
                state.bookingSteps = generalHomeTestDirveBookingSteps;
            else {
                if (state.isBookingReserved) {
                    state.bookingSteps = generalBookingSteps;
                } else {
                    state.bookingSteps = seeShowroomBookingSteps;
                }
            }
        },

        setSelectedCar: (
            state,
            action: PayloadAction<CarDetailsType | null>
        ) => {
            state.selectedCar = action.payload;
        },
        setShowroom: (state, action: PayloadAction<Showroom | null>) => {
            state.showroom = action.payload;
        },
        setCalendar: (state, action: PayloadAction<TimeSlot[]>) => {
            const calendar = action.payload;
            let daysCount = 0;
            let slotsCount = 0;
            const uniqueDays = calendar
                .map(item => item?.available_at)
                .filter((value, index, self) => self.indexOf(value) === index);
            const clusturedSlots: any = [];
            uniqueDays.forEach((day, index) => {
                const cluster: any = calendar.filter(
                    item => item.available_at === uniqueDays[index]
                );
                cluster.isDisabled = cluster.every(
                    (slot: TimeSlot) => !slot.is_slot_available
                );
                if (!cluster.isDisabled) {
                    daysCount += 1;
                    cluster.forEach((slot: TimeSlot) => {
                        if (slot.is_slot_available) slotsCount += 1;
                    });
                }
                clusturedSlots.push(cluster);
            });
            state.availableDaysCount = daysCount;
            state.availableSlotsCount = slotsCount;
            state.calendar = clusturedSlots;
        },

        setToaster: (state, action: PayloadAction<Toaster>) => {
            state.toaster = action.payload;
        },
        setConfirmedBookingData: (state, action: PayloadAction<any>) => {
            state.confirmedBookingData = action.payload;
        },
        setBookingSteps: (state, action: PayloadAction<BookingStep[]>) => {
            state.bookingSteps = action.payload;
        },
        setActionBtnDisabled: (state, action: PayloadAction<boolean>) => {
            state.actionBtnDisabled = action.payload;
        },
        setShowOTP: (state, action: PayloadAction<boolean>) => {
            state.showOTP = action.payload;
        },
        setOTPError: (state, action: PayloadAction<string>) => {
            state.otpError = action.payload;
        },
        setSelectedCity: (state, action: PayloadAction<Option | null>) => {
            state.selectedCity = action.payload;
        },
        setSelectedArea: (state, action: PayloadAction<Option | null>) => {
            state.selectedArea = action.payload;
        },
        setPaymentSrc: (state, action: PayloadAction<string>) => {
            state.paymentSrc = action.payload;
        },
        setLandmark: (state, action: PayloadAction<string>) => {
            state.landmark = action.payload;
        },
        setBuilding: (state, action: PayloadAction<string>) => {
            state.building = action.payload;
        },
        setStreet: (state, action: PayloadAction<string>) => {
            state.street = action.payload;
        },
        setCheckoutSubmitBtnText: (state, action: PayloadAction<string>) => {
            state.checkoutSubmitBtnText = action.payload;
        },
        reset(state) {
            for (const key in initialState) {
                if (key === 'isLoading') {
                    continue;
                }
                state[key] = initialState[key];
            }
        },
        toggleLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});

export const {
    setSelectedDay,
    setSelectedTime,
    nextStep,
    prevStep,
    setSelectedCarID,
    setBookingType,
    setSelectedCar,
    setShowroom,
    setCalendar,
    setToaster,
    setConfirmedBookingData,
    setActiveStep,
    setBookingSteps,
    setActionBtnDisabled,
    setShowOTP,
    setOTPError,
    setResevationType,
    setSelectedArea,
    setSelectedCity,
    setPaymentSrc,
    setBuilding,
    setLandmark,
    setStreet,
    setCheckoutSubmitBtnText,
    reset,
    toggleLoading,
} = checkoutReducer.actions;

export default checkoutReducer.reducer;
