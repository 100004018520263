import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EInspectionType,
  IContanctInfo_SELL,
  IKilometrage_SELL,
  IMake_SELL,
  IModel_SELL,
  IRegion_SELL,
  ISellCarForm,
  ISellFormData,
  IStepper_SELL,
  IStepperItem_SELL,
  ITrafficPoint_SELL,
  ITransmission_SELL,
  IYear_SELL,
} from '@/types';

const initialState: ISellFormData = {
  path1: {
    make: {
      nameEn: '',
      nameAr: '',
      id: '',
      image: '',
      inShoppingList: true,
    },
    model: { nameEn: '', nameAr: '', id: '', inShoppingList: true },
    region: {
      id: '',
      bigArea: '',
      smallArea: '',
      name: '',
      bigAreaAr: '',
      smallAreaAr: '',
      address: '',
      coordinate: null,
      polygon: [],
      warehouse: false,
      serviceable: false,
      addressCity: '',
      sendPinLocation: false,
    },
    contactInfo: { name: '', phone: '', email: '' },
    kilometers: { value: 0, displayed: '', inShoppingList: true },
    year: { year: '', inShoppingList: true },
    transmission: { transmission: '', inShoppingList: true, id: '' },
    trafficPoint: { trafficPoint: '', inShoppingList: true, id: '' },
    inspectionType: EInspectionType.HOME,
  },
  choosenPath: 'path1',
  stepper: {
    inspectionType: {
      allowed: false,
      active: false,
      label: 'inspectionType',
      icon: '',
      step: 1,
      value: '',
      iconValue: '',
      mainIcon: '',
      backgroundColor: '',
      inShoppingList: true,
      showAlert: false,
    },
    make: {
      allowed: false,
      active: false,
      label: 'make',
      icon: '',
      step: 1,
      value: '',
      iconValue: '',
      mainIcon: '',
      backgroundColor: '',
      inShoppingList: true,
      showAlert: false,
    },
    model: {
      allowed: false,
      active: false,
      label: 'model',
      icon: '',
      step: 2,
      value: '',
      iconValue: '',
      mainIcon: '',
      backgroundColor: '',
      inShoppingList: true,
      showAlert: false,
    },
    year: {
      allowed: false,
      active: false,
      label: 'year',
      icon: '',
      step: 3,
      value: '',
      iconValue: '',
      mainIcon: '',
      backgroundColor: '',
      inShoppingList: true,
      showAlert: false,
    },
    kilometers: {
      allowed: false,
      active: false,
      label: 'kilometers',
      icon: '',
      step: 4,
      value: '',
      iconValue: '',
      mainIcon: '',
      backgroundColor: '',
      inShoppingList: true,
      showAlert: false,
    },
    otherinfo: {
      allowed: false,
      active: false,
      icon: '',
      label: 'otherinfo',
      step: 5,
      value: '',
      inShoppingList: true,
      backgroundColor: '',
      mainIcon: '',
      showAlert: false,
    },
    region: {
      allowed: false,
      active: false,
      label: 'region',
      icon: '',
      step: 6,
      value: '',
      iconValue: '',
      mainIcon: '',
      backgroundColor: '',
      inShoppingList: true,
      showAlert: false,
    },
    contactInfo: {
      allowed: false,
      active: false,
      label: 'contact',
      icon: '',
      step: 7,
      value: '',
      iconValue: '',
      mainIcon: '',
      backgroundColor: '',
      inShoppingList: true,
      showAlert: false,
    },
  },
  currentStep: {
    allowed: false,
    active: false,
    label: 'make',
    icon: '',
    step: 1,
    value: '',
    iconValue: '',
    mainIcon: '',
    backgroundColor: '',
    inShoppingList: true,
    showAlert: false,
  },
};

export const sellCarFormReducer = createSlice({
  name: 'sellCarForm',
  initialState,
  reducers: {
    setPath1IntialState: (state, action: PayloadAction<ISellCarForm>) => {
      state.path1.make = action.payload.make;
      state.path1.model = action.payload.model;
      state.path1.region = action.payload.region;
      state.path1.contactInfo = action.payload.contactInfo;
      state.path1.kilometers = action.payload.kilometers;
      state.path1.year = action.payload.year;
      state.path1.transmission = action.payload.transmission;
      state.path1.trafficPoint = action.payload.trafficPoint;
    },
    setPath1Make: (state, action: PayloadAction<IMake_SELL>) => {
      state.path1.make = action.payload;
    },
    setPath1Model: (state, action: PayloadAction<IModel_SELL>) => {
      state.path1.model = action.payload;
    },
    setPath1Region: (state, action: PayloadAction<IRegion_SELL>) => {
      state.path1.region = action.payload;
    },
    setPath1ContactInfo: (state, action: PayloadAction<IContanctInfo_SELL>) => {
      state.path1.contactInfo = action.payload;
    },
    setPath1Kilometers: (state, action: PayloadAction<IKilometrage_SELL>) => {
      state.path1.kilometers = action.payload;
    },
    setPath1Year: (state, action: PayloadAction<IYear_SELL>) => {
      state.path1.year = action.payload;
    },
    setPath1Transmission: (state, action: PayloadAction<ITransmission_SELL>) => {
      state.path1.transmission = action.payload;
    },
    setPath1TrafficPoint: (state, action: PayloadAction<ITrafficPoint_SELL>) => {
      state.path1.trafficPoint = action.payload;
    },

    setPath: (state, action: PayloadAction<'path1' | 'path2'>) => {
      state.choosenPath = action.payload;
    },
    setStepper: (state, action: PayloadAction<IStepper_SELL>) => {
      state.stepper = action.payload;
    },
    setStepperMake: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.stepper.make = action.payload;
    },
    setStepperModel: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.stepper.model = action.payload;
    },
    setStepperYear: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.stepper.year = action.payload;
    },
    setStepperKilometers: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.stepper.kilometers = action.payload;
    },
    setStepperOtherInfo: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.stepper.otherinfo = action.payload;
    },
    setStepperRegion: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.stepper.region = action.payload;
    },
    setStepperContactInfo: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.stepper.contactInfo = action.payload;
    },
    setStepperCurrentActiveStep: (state, action: PayloadAction<IStepperItem_SELL>) => {
      state.currentStep = action.payload;
    },

    clearPath1Data: (state) => {
      state.path1.make = {
        nameEn: '',
        nameAr: '',
        id: '',
        image: '',
        inShoppingList: true,
      };
      state.path1.model = {
        nameEn: '',
        nameAr: '',
        id: '',
        inShoppingList: true,
      };
      state.path1.region = {
        id: '',
        bigArea: '',
        smallArea: '',
        name: '',
        bigAreaAr: '',
        smallAreaAr: '',
        address: '',
        coordinate: null,
        polygon: [],
        serviceable: false,
        warehouse: false,
        addressCity: '',
        sendPinLocation: true,
      };
      state.path1.trafficPoint = {
        trafficPoint: '',
        inShoppingList: true,
        id: '',
      };
      state.path1.transmission = {
        transmission: '',
        inShoppingList: true,
        id: '',
      };
      state.path1.contactInfo = { name: '', phone: '', email: '' };
      state.path1.kilometers = {
        value: 0,
        displayed: '',
        inShoppingList: true,
      };
      state.path1.year = { year: '', inShoppingList: true };
      state.choosenPath = 'path1';
    },
  },
});

export const {
  setPath1IntialState,
  setPath1Make,
  setPath1Model,
  setPath1Region,
  setPath1ContactInfo,
  setPath1Kilometers,
  setPath1Year,
  setPath1TrafficPoint,
  setPath1Transmission,
  setPath,
  setStepper,
  setStepperMake,
  setStepperModel,
  setStepperYear,
  setStepperKilometers,
  setStepperRegion,
  setStepperContactInfo,
  setStepperOtherInfo,
  clearPath1Data,
  setStepperCurrentActiveStep,
} = sellCarFormReducer.actions;

export default sellCarFormReducer.reducer;
