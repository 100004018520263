'use client';
import { useEffect, useState } from 'react';

type OSType = 'Windows' | 'macOS' | 'Linux' | 'iOS' | 'Android' | 'unknown';

export function useOSType(): OSType {
  const [osType, setOSType] = useState<OSType>('unknown');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/windows/.test(userAgent)) {
      setOSType('Windows');
    } else if (/mac os/.test(userAgent)) {
      setOSType('macOS');
    } else if (/linux/.test(userAgent)) {
      setOSType('Linux');
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      setOSType('iOS');
    } else if (/android/.test(userAgent)) {
      setOSType('Android');
    }
  }, []);

  return osType;
}
