export { default as TransmissionIcon } from './transmissionIcon.svg';
export { default as Speedometer } from './speedometer.svg';
export { default as CalendarCardIcon } from './calenderIcon.svg';
export { default as MakeIconGreen } from './makeIconGreen.svg';
export { default as CalendarIconGreen } from './calendarIconGreen.svg';
export { default as KMIconGreen } from './kMIconGreen.svg';
export { default as LocationIconGreen } from './locationIconGreen.svg';
export { default as ModelIconGreen } from './modelIconGreen.svg';
export { default as ContactIconGreen } from './contactIconGreen.svg';
export { default as TransmissionIconGreen } from './transmissionIcon.svg';
export { default as PriceIconGreen } from './priceIconGreen.svg';

export { default as BellIconGreen } from './bellIconGreen.svg';
export { default as FacebookLogo } from './facebookLogo.svg';
export { default as LinkedinLogo } from './linkedinLogo.svg';
export { default as PhoneIcon } from './phoneIcon.svg';
export { default as InstagramLogo } from './InstagramLogo.svg';
export { default as ClockIcon } from './clock.svg';
export { default as CarGreenIcon } from './carGreenIcon.svg';
export { default as GrayClockIcon } from 'public/Images/Icons/grayClock.svg';
export {default as CalendarCheckIcon} from './calendar-check.svg';
export { default as SettingsBlackIcon } from './settingsBlackIcon.svg';
export { default as CallBlackIcon } from './callBlackIcon.svg';
export { default as LocationBlackIcon } from './locationBlackIcon.svg';
export { default as GaugeBlackIcon } from './gaugeBlackIcon.svg';
export { default as CalendarBlackIcon } from './calendarBlackIcon.svg';
export { default as SpeedcarBlackIcon } from './speedcarBlackIcon.svg';
export { default as LocationGreenIcon } from './locationGreenIcon.svg';
export { default as QuestionGreenIcon } from './questionGreenIcon.svg';
export { default as PapersGreenIcon } from './papersGreenIcon.svg';
export { default as CalendarGreenIcon } from './calendarGreenIcon.svg';
export { default as MessagePrimaryIcon } from './messagePrimaryIcon.svg';
export { default as InfoCircleIcon } from './info-circle.svg';
export { default as InfoCircleIconWhite } from './info-circle-white.svg';
export { default as LocationIcon } from './LocationIcon.svg';
export { default as LinesGreen } from './LinesGreen.svg';
export { default as LogoutBlack } from './LogoutBlack.svg';
export { default as EnvelopeBlack } from './EnvelopeBlack.svg';
export { default as ProfileIconGrey } from './profileIconGrey.svg';
export { default as ProfileIconBlack } from './profileIconBlack.svg';
export { default as ProfileIconGreen } from './profileIconGreen.svg';
export { default as SellCarIconBlack } from './sellCarReqIconBlack.svg';
export { default as BookedCarsIconBlack } from './bookedCarsIconBlack.svg';
export { default as SellCarIconGrey } from './sellCarReqIconGrey.svg';
export { default as BookedCarsIconGrey } from './bookedCarsIconGrey.svg';
export { default as LogoutGrey } from './logoutGrey.svg';
export { default as WhiteArrowDownGreenBackground } from './whiteArrowDownGreenBackground.svg';
