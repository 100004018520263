import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISortingState, SORTING_PLANS } from '@/types/filter.types';

const initialState: ISortingState = {
    sorting_plan: SORTING_PLANS.MOST_RELEVANT,
};

export const sortingReduceer = createSlice({
    name: 'sort',
    initialState,
    reducers: {
        setSortingOption: (
            state: ISortingState,
            action: PayloadAction<SORTING_PLANS>
        ) => {
            state.sorting_plan = action.payload;
        },
    },
});

export const { setSortingOption } = sortingReduceer.actions;

export default sortingReduceer.reducer;
