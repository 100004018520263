'use client';
import { useEffect, useState } from 'react';

declare global {
    interface Window {
        dataLayer?: Record<string, any>[];
        google_optimize: any;
    }
}
const useExperiment = (experimentId: string) => {
    const [variant, setVariant] = useState<`${number}`>();
    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({ event: 'optimize.activate' });
        }
        const intervalId = setInterval(() => {
            if (window.google_optimize !== undefined) {
                const variantLoaded = window.google_optimize.get(experimentId);

                setVariant(variantLoaded);
                clearInterval(intervalId);
            }
        }, 100);
    });
    return variant;
};

export default useExperiment;
