import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EInspectionType,
  IFormData_SELL_Reducer,
  ISellCarForm,
  ISellCarFormDataItem,
} from '@/types';

const initialState: IFormData_SELL_Reducer = {
  formDataItems: [],
  baseFormData: {
    make: {
      nameEn: '',
      nameAr: '',
      id: '',
      image: '',
      inShoppingList: true,
    },
    model: { nameEn: '', nameAr: '', id: '', inShoppingList: true },
    region: {
      id: '',
      bigArea: '',
      smallArea: '',
      name: '',
      bigAreaAr: '',
      smallAreaAr: '',
      address: '',
      coordinate: null,
      polygon: [],
      warehouse: false,
      serviceable: false,
      addressCity: '',
      sendPinLocation: false,
    },
    contactInfo: { name: '', phone: '', email: '' },
    kilometers: { value: 0, displayed: '', inShoppingList: true },
    year: { year: '', inShoppingList: true },
    transmission: { transmission: '', inShoppingList: true, id: '' },
    trafficPoint: { trafficPoint: '', inShoppingList: true, id: '' },
    inspectionType: EInspectionType.HOME,
  },
};

export const sellFomData = createSlice({
  name: 'sellFomData',
  initialState,
  reducers: {
    setFormDataItems: (state, action: PayloadAction<ISellCarFormDataItem[]>) => {
      state.formDataItems = action.payload;
    },
    setBaseFormData: (state, action: PayloadAction<ISellCarForm>) => {
      state.baseFormData = action.payload;
    },
  },
});

export const { setFormDataItems, setBaseFormData } = sellFomData.actions;

export default sellFomData.reducer;
