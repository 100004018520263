export interface MakeFilter {
    text: string;
    models: string[];
    kilometers: string[];
    years: string[];
    price: string[];
    selected: boolean;
}
export interface ModelFilter {
    makes: string;
    text: string;
    kilometers: string[];
    years: string[];
    price: string[];
    selected: boolean;
}
export interface YearFilter {
    text: number;
    makes: string[];
    selected: boolean;
}
export interface ColorFilter {
    text: string;
    makes: string[];
    selected: boolean;
}
// filters
export enum FILTER_NAMES {
    MAKE_MODEL = 'Make & Model',
    AVAILABLE = 'Car Status',
    PRICE = 'price',
    MODEL_YEAR = 'year',
    KILOMETERS = 'kilometers',
    BODY_STYLE = 'Body Style',
    TRANSMISSION = 'Transmission',
}
//enums
export enum YEARS {
    O12 = '2012',
    O13 = '2013',
    O14 = '2014',
    O15 = '2015',
    O16 = '2016',
    O17 = '2017',
    O18 = '2018',
    O19 = '2019',
    O20 = '2020',
    O21 = '2021',
    O22 = '2022',
}
export enum PRICE {
    ONE = '100000', // from 100,000 to 199,999
    TWO = '200000',
    THREE = '300000',
    FOUR = '400000',
    FIVE = '500000',
    SIX = '600000',
    SEVEN = '700000',
    EIGHT = '800000',
    NINE = '900000',
    TEN = '1000000',
}
export enum KILOMETERS {
    ONE = '10000', // from 0 to 10,000
    TWO = '30000', // from 0 to 30,000
    THREE = '50000',
    FOUR = '75000',
    FIVE = '100000',
    SIX = '125000',
    SEVEN = '150000',
}
export enum BODY_TYPE {
    COUPE = 'coupe',
    SEDAN = 'sedan',
    SUV = 'suv',
    TRUCK = 'truck',
    VAN = 'van',
    CONVERTIBLE = 'convertible',
    MINIVAN = 'minivan',
    HATCHBACK = 'hatchback',
    FOUR_X_FOUR = '4X4',
}
export enum TRANSMISSION {
    AUTOMATIC = 'automatic',
    MANUAL = 'manual',
}

export interface FilterTabsInterface {
    text: string;
    selected: boolean;
    id: string | number;
    count: number;
}

// passed to filter ui components
export interface FilterDisplayItemData {
    text: string;
    selected: boolean;
    count: number;
    value: string;
}
export interface YearFilterDisplayItemData {
    text: number;
    selected: boolean;
    count: number;
    value: number;
}

export interface MakeFilterUiOject {
    text: string;
    selected: boolean;
    count: number;
    value: string;
    models: FilterDisplayItemData[];
}

// store data for a specific filter. ex: 2012:{make: [Kia, Tyota], count: 2}
export interface FilterItemData {
    value: string;
    makes: string[];
    count: number;
}

export interface FilterInnerCarInfoObject {
    kilometers: KILOMETERS;
    bodyType: BODY_TYPE;
    price: PRICE;
    transmission: TRANSMISSION;
}
// object htat represent the value and count of a single item. ex: {value: 2012, count: 1}
export interface FilterInnerObjectItem {
    value: string;
    count: number;
    cars: FilterInnerCarInfoObject[];
}
export interface ModelFilterObject {
    value: string;
    year: { [key in YEARS]: FilterInnerObjectItem };
}

export interface FilterObject {
    makeModel: MakeModel;
    price: number[];
    year: number[];
    kilometers: string;
    bodyType: string[];
    transmission: string[];
    available: string;
}

export type MakeModel = Record<string, { models: string[]; selected: boolean }>;

export interface FilterTagMobileObject {
    text: string | number;
    filterType: string;
}

export enum SORTING_PLANS {
    'MOST_RELEVANT' = 'MOST_RELEVANT',
    'RECENTLY_ADDED' = 'RECENTLY_ADDED',
    'PRICE_ASC' = 'PRICE_ASC',
    'PRICE_DESC' = 'PRICE_DESC',
    'YEAR_ASC' = 'YEAR_ASC',
    'YEAR_DESC' = 'YEAR_DESC',
    'KM_ASC' = 'KM_ASC',
    'KM_DESC' = 'KM_DESC',
}

export interface ISortingState {
    sorting_plan: SORTING_PLANS;
}
export enum possibleBuyUseCarsSources {
    'Homepage_Banner' = 'Homepage_Banner',
    'Buy_Header' = 'Buy_Header',
    'Buy_Footer' = 'Buy_Footer',
    'Browse_Car_Sidebar' = 'Browse_Car_Sidebar',
    'mobile_side_menu' = 'mobile_side_menu',
    'Browse_Cars_Homepage' = 'Browse_Cars_Homepage',
    'Other' = 'Other',
    'homepage_body_types' = 'homepage_body_types',
    'financing' = 'financing',
    'footer' = 'footer',
    'notify_me' = 'notify_me',
    'create_alert' = 'create_alert',
    'desktop_header' = 'desktop_header',
    'not_found_page' = 'not_found_page',
}
