'use client';
import { setSortingOption } from '@/redux/reducers/sorting.reducer';

import { useAppDispatch, useAppSelector } from './use-redux.hook';
import { SORTING_PLANS } from '@/types/filter.types';
import { useAnalytics } from '@/integrations';

const useSort = () => {
    const dispatch = useAppDispatch();
    const { trackEvent } = useAnalytics();
    const { sorting_plan } = useAppSelector(state => state.sort);
    const setSortOptions = (
        sortOption: SORTING_PLANS,
        allowTracking = true
    ) => {
        if (!SORTING_PLANS[sortOption] || sortOption === sorting_plan) return;
        dispatch(setSortingOption(sortOption));
        if (allowTracking) {
            trackEvent('Action: Sort', {
                usedSorting: sortOption,
            });
        }
    };

    return {
        setSortOptions,
        sorting_plan,
    };
};
export default useSort;
