import { useEffect, useState } from 'react';

const cacheImages = async (imgsURl: string[]) => {
    const imgsPromises = imgsURl.map(imgurl => {
        return new Promise(function (resolve, reject) {
            const img = new Image();
            img.src = imgurl;
            img.onload = () => {
                resolve(img.src);
            };
            img.onerror = () => {
                reject(img.src);
            };
            window[imgurl] = img.src;
        });
    });

    const cachedImages = await Promise.all(imgsPromises);
    return cachedImages;
};

export default function useImageCaching(imageList: string[]) {
    const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);
    const [cachedImages, setCachedImages] = useState<string[]>([]);

    useEffect(() => {
        let isCancelled = false;

        async function cacheToState() {
            if (isCancelled) {
                return;
            }
            cacheImages(imageList).then((imgs: string[]) => {
                setCachedImages(imgs);
                setImagesPreloaded(true);
            });
        }

        cacheToState();

        return () => {
            isCancelled = true;
        };
    }, [imageList]);

    return { imagesPreloaded, cachedImages };
}
