import {
    CarDBStatus,
    CarStatus,
    defaultEmiFilter,
    defaultPriceFilter,
    defaultYearFilter,
    getDefaultIClientRetailFilters,
    IClientRetailFilters,
    ISearchTerms,
    IServerRetailFilters,
    IServerRetailFiltersKeys,
} from '@/types';
import { TEsFilters } from '@/api-requests/car-listing-elastic.apis';

export type comparableTypes =
    | string
    | string[]
    | number
    | number[]
    | boolean
    | boolean[];

export interface TFilterTerm<T> {
    keyName: keyof T;
    keyValue: comparableTypes;
}
export const slugify = (s: string): string =>
    String(s).toLowerCase().replace(/\W/g, '');

export const buildUrl = (filters: IClientRetailFilters) => {
    const params = ['/buy-used-cars'];
    const queryParams = [];

    if (filters) {
        const filteredMakes = Object.keys(filters.makeModel);
        if (filteredMakes.length === 1) {
            params.push(filteredMakes[0]);
            const filteredModels = Object.keys(
                filters.makeModel[filteredMakes[0]].models
            );
            if (filteredModels.length === 1) {
                params.push(filteredModels[0]);
            } else if (filteredModels.length > 1) {
                queryParams.push(
                    `${IServerRetailFiltersKeys.model}=${filteredModels.join(
                        ','
                    )}`
                );
            }
        } else if (filteredMakes.length > 1) {
            queryParams.push(
                `${IServerRetailFiltersKeys.make}=${filteredMakes.join(',')}`
            );
            const modelGroup = [];
            for (const make of filteredMakes) {
                modelGroup.push(...Object.keys(filters.makeModel[make].models));
            }
            queryParams.push(
                `${IServerRetailFiltersKeys.model}=${modelGroup.join(',')}`
            );
        }

        if (filters.kilometers) {
            queryParams.push(
                `${IServerRetailFiltersKeys.kilometers}=${filters.kilometers}`
            );
        }

        if (filters.year && filters.year.min != defaultYearFilter.min) {
            const yearfilter = [filters.year.min];
            if (filters.year.max != defaultYearFilter.max) {
                yearfilter.push(filters.year.max);
            }
            queryParams.push(
                `${IServerRetailFiltersKeys.year}=${yearfilter.join(',')}`
            );
        }
        if (filters.price && filters.price.min != defaultPriceFilter.min) {
            const pricefilter = [filters.price.min];
            if (filters.price.max != defaultPriceFilter.max) {
                pricefilter.push(filters.price.max);
            }
            queryParams.push(
                `${IServerRetailFiltersKeys.price}=${pricefilter.join(',')}`
            );
        }
        if (filters.emi && filters.emi.min != defaultEmiFilter.min) {
            const emifilter = [filters.emi.min];
            if (filters.emi.max != defaultEmiFilter.max) {
                emifilter.push(filters.emi.max);
            }
            queryParams.push(
                `${IServerRetailFiltersKeys.emi}=${emifilter.join(',')}`
            );
        }
        if (filters.status) {
            queryParams.push(
                `${IServerRetailFiltersKeys.status}=${slugify(filters.status)}`
            );
        }
        if (filters.discounted) {
            queryParams.push(`${IServerRetailFiltersKeys.discounted}=1`);
        }
        const filteredBodyStyle = Object.keys(filters.bodystyle).join(',');
        if (filteredBodyStyle) {
            queryParams.push(
                `${IServerRetailFiltersKeys.bodystyle}=${filteredBodyStyle}`
            );
        }
        const filteredTransmissions = Object.keys(filters.transmission).join(
            ','
        );
        if (filteredTransmissions) {
            queryParams.push(
                `${IServerRetailFiltersKeys.transmission}=${filteredTransmissions}`
            );
        }
    }
    // build make and model .

    const urlParts = [params.join('/')];
    if (queryParams.length > 0) {
        urlParts.push(queryParams.join('&'));
    }
    return urlParts.join('?');
};

const specialNames: Record<string, string> = {
    mini: 'MINI',
    mg: 'MG',
    bmw: 'BMW',
};

export const formatBrandName = (name: string) => {
    if (specialNames[name]) {
        return specialNames[name];
    } else {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
};

export const formatYearForDisplay = (yearArr: number[] | string[]) => {
    if (!yearArr[1]) {
        return `${yearArr[0]}`;
    }

    if (yearArr[0] === yearArr[1]) {
        return `${yearArr[0]}`;
    }

    return `${yearArr[0]} - ${yearArr[1]}`;
};
export const formatPriceForDisplay = (
    priceArr: number[],
    lang: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    t: Function
) => {
    const from = priceArr[0] / 1000;
    const to = priceArr[1] / 1000;
    let toStr = `${to}K`;
    if (to >= 1000) toStr = `${to / 1000}M`;

    let fromStr = `${from}K`;
    if (from >= 1000) fromStr = `${from / 1000}M`;

    return lang === 'ar'
        ? `${toStr} - ${fromStr} ${t('EGP')}`
        : `${fromStr} - ${toStr} ${t('EGP')}`;
};
export const serverRetailFiltersToClient = (
    inputFilters: IServerRetailFilters,
    searchTerms: ISearchTerms
): IClientRetailFilters => {
    const result: IClientRetailFilters = getDefaultIClientRetailFilters();
    const {
        make,
        transmission,
        kilometers,
        year,
        price,
        status,
        bodystyle,
        emi,
        discounted,
    } = inputFilters;
    const { model } = inputFilters;
    const possibleModels = [];
    let totalAssignedModel = 0;

    //Handle makes.
    if (make) {
        const makesList = Array.from(
            new Set(make.split(',').map(m => slugify(m)))
        );

        for (const make of makesList) {
            if (searchTerms.makeModelTree[make]) {
                result.makeModel[make] = {
                    make: searchTerms.makeModelTree[make].make,
                    models: {},
                };

                for (const model of searchTerms.makeModelTree[make]
                    .modelsSlug) {
                    possibleModels.push(model);
                }
            }
        }
    }

    const addModelToResults = (model: string) => {
        const modelsList = Array.from(
            new Set(model.split(',').map(m => slugify(m)))
        );
        for (const model of modelsList) {
            if (searchTerms.modelsTree[model]) {
                result.makeModel[searchTerms.modelsTree[model].make].models[
                    model
                ] = searchTerms.modelsTree[model].model;
                totalAssignedModel++;
            }
        }
    };
    if (model) {
        addModelToResults(model);
    }

    if (!model || totalAssignedModel === 0) {
        addModelToResults(possibleModels.join(','));
    }

    //Handle models

    //Handle transmission
    if (transmission) {
        const transmissionsList = Array.from(new Set(transmission.split(',')));

        for (const transmission of transmissionsList) {
            result.transmission[transmission] = transmission;
        }
    }

    //Handle year
    if (year) {
        const yearFilter = year.split(',');
        if (yearFilter[0]) {
            result.year.min = Number(yearFilter[0]);
        }
        if (yearFilter[1]) {
            result.year.max = Number(yearFilter[1]);
        }
    }

    //Handle kilometers
    if (kilometers) {
        result.kilometers = Number(kilometers);
    }

    //Handle price
    if (price) {
        const pricesFilter = price.split(',');
        let min = searchTerms.priceTerms.min;
        let max = searchTerms.priceTerms.max;
        if (Number(pricesFilter[0]) > min) {
            min = Math.max(Number(pricesFilter[0]), min);
        }
        if (Number(pricesFilter[1]) < max) {
            max = Math.min(Number(pricesFilter[1]), max);
        }
        if (
            min != searchTerms.priceTerms.min ||
            max !== searchTerms.priceTerms.max
        ) {
            result.price = { min, max };
        }
    }

    //Handle Emi
    if (emi) {
        const emiFilter = emi.split(',');
        let min = searchTerms.emiTerms.min;
        let max = searchTerms.emiTerms.max;
        if (Number(emiFilter[0]) > min) {
            min = Math.max(Number(emiFilter[0]), min);
        }
        if (Number(emiFilter[1]) < max) {
            max = Math.min(Number(emiFilter[1]), max);
        }
        if (
            min != searchTerms.emiTerms.min ||
            max !== searchTerms.emiTerms.max
        ) {
            result.emi = { min, max };
        }
    }

    //Handle bodyStyle
    if (bodystyle) {
        const bodyStyleList = Array.from(
            new Set(bodystyle.split(',').map(b => slugify(b)))
        );

        for (const bodyStyle of bodyStyleList) {
            if (searchTerms.bodyStyleTerms.bodyStyles[bodyStyle]) {
                result.bodystyle[bodyStyle] =
                    searchTerms.bodyStyleTerms.bodyStyles[bodyStyle];
            }
        }
    }

    //Handle Status
    if (status) {
        result.status = status;
    }

    //Handel Discounted
    if (discounted) {
        result.discounted = true;
    }

    return result;
};
export const convertServerToEsFilters = (
    inputFilters: IServerRetailFilters,
    searchTerms: ISearchTerms
): TEsFilters => {
    const {
        make,
        model,
        transmission,
        kilometers,
        year,
        price,
        status,
        bodystyle,
        emi,
        discounted,
    } = inputFilters;

    const esFilters: TEsFilters = {
        must: [],
        must_not: [],
        should: [],
    };

    //Handle makes.
    const filteredMakes: string[] = [];
    const possibleModels = [];
    if (make) {
        const makesList = Array.from(
            new Set(make.split(',').map(m => slugify(m)))
        );

        for (const make of makesList) {
            if (searchTerms.makeModelTree[make]) {
                filteredMakes.push(make);
                // esFilters.should.push({
                //     term: {
                //         make: searchTerms.makeModelTree[make].make,
                //     },
                // });
                for (const model of searchTerms.makeModelTree[make]
                    .modelsSlug) {
                    possibleModels.push({
                        term: {
                            model: searchTerms.modelsTree[model].model,
                        },
                    });
                }
            }
        }
    }

    //Handle models
    let failedToAssignModel = true;
    if (model) {
        const modelsList = Array.from(
            new Set(model.split(',').map(m => slugify(m)))
        );
        for (const model of modelsList) {
            if (
                searchTerms.modelsTree[model] &&
                filteredMakes.includes(searchTerms.modelsTree[model].make)
            ) {
                failedToAssignModel = false;
                esFilters.should.push({
                    term: {
                        model: searchTerms.modelsTree[model].model,
                    },
                });
            }
        }
    }
    if (!model || failedToAssignModel) {
        esFilters.should.push(...possibleModels);
    }

    //Handle transmission
    if (transmission) {
        const transmissionFilterContainer = {
            bool: {
                should: [],
                minimum_should_match: 1,
            },
        };
        const transmissionsList = Array.from(new Set(transmission.split(',')));

        for (const transmission of transmissionsList) {
            transmissionFilterContainer.bool.should.push({
                term: {
                    transmission: transmission,
                },
            });
        }
        if (transmissionFilterContainer.bool.should.length > 0) {
            esFilters.must.push(transmissionFilterContainer);
        }
    }

    //Handle year
    if (year) {
        const yearFilter = year.split(',');

        if (yearFilter.length === 1) {
            esFilters.must.push({
                range: {
                    year: {
                        gte: Number(yearFilter[0]),
                    },
                },
            });
        } else if (yearFilter.length === 2) {
            esFilters.must.push({
                range: {
                    year: {
                        gte: Number(yearFilter[0]),
                        lte: Number(yearFilter[1]),
                    },
                },
            });
        }
    }

    //Handle kilometers
    if (kilometers) {
        esFilters.must.push({
            range: {
                kilometers: {
                    lte: Number(kilometers),
                },
            },
        });
    }

    //Handle price
    if (price) {
        const pricesFilter = price.split(',');
        let min = searchTerms.priceTerms.min;
        let max = searchTerms.priceTerms.max;
        if (Number(pricesFilter[0]) > min) {
            min = Math.max(Number(pricesFilter[0]), min);
        }
        if (Number(pricesFilter[1]) < max) {
            max = Math.min(Number(pricesFilter[1]), max);
        }
        if (
            min != searchTerms.priceTerms.min ||
            max !== searchTerms.priceTerms.max
        ) {
            esFilters.must.push({
                bool: {
                    should: [
                        {
                            bool: {
                                must: [
                                    {
                                        range: {
                                            sylndrSellingPrice: {
                                                gte: min,
                                                lte: max,
                                            },
                                        },
                                    },
                                    {
                                        term: {
                                            discountPrice: 0,
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            range: {
                                discountPrice: {
                                    gte: min,
                                    lte: max,
                                },
                            },
                        },
                    ],
                    minimum_should_match: 1,
                },
            });
        }
    }

    //Handle price
    if (emi) {
        const emiFilter = emi.split(',');
        let min = searchTerms.emiTerms.min;
        let max = searchTerms.emiTerms.max;
        if (Number(emiFilter[0]) > min) {
            min = Math.max(Number(emiFilter[0]), min);
        }
        if (Number(emiFilter[1]) < max) {
            max = Math.min(Number(emiFilter[1]), max);
        }
        if (
            min != searchTerms.emiTerms.min ||
            max !== searchTerms.emiTerms.max
        ) {
            esFilters.must.push({
                range: {
                    installmentPrice: {
                        gte: min,
                        lte: max,
                    },
                },
            });
        }
    }

    //Handle bodyStyle
    if (bodystyle) {
        const bodyStyleList = Array.from(
            new Set(bodystyle.split(',').map(b => slugify(b)))
        );
        const bodyStyleFilterContainer = {
            bool: {
                should: [],
                minimum_should_match: 1,
            },
        };

        for (const bodyStyle of bodyStyleList) {
            if (searchTerms.bodyStyleTerms.bodyStyles[bodyStyle]) {
                bodyStyleFilterContainer.bool.should.push({
                    term: {
                        bodyStyle:
                            searchTerms.bodyStyleTerms.bodyStyles[bodyStyle],
                    },
                });
            }
        }

        if (bodyStyleFilterContainer.bool.should.length > 0) {
            esFilters.must.push(bodyStyleFilterContainer);
        }
    }

    //Handle Status
    if (status) {
        if (status === CarStatus.BEING_SOLD) {
            esFilters.must.push({
                term: {
                    isPurchaseInProgress: true,
                },
            });
        } else if (status === CarStatus.RESERVED) {
            esFilters.must.push({
                term: {
                    status: CarDBStatus.RESERVED,
                },
            });
        } else {
            esFilters.must.push({
                term: {
                    status: CarDBStatus.UNRESERVED,
                },
            });
        }
    }
    if (discounted) {
        esFilters.must.push({
            range: {
                discountPrice: {
                    gt: Number(0),
                },
            },
        });
    }
    return esFilters;
};
export const convertClientToEsFilters = (
    inputFilters: IClientRetailFilters,
    searchTerms: ISearchTerms
): TEsFilters => {
    const esFilters: TEsFilters = {
        must: [],
        must_not: [],
        should: [],
    };
    const makesList = Object.keys(inputFilters.makeModel);

    //Handle makes.
    if (makesList.length > 0) {
        for (const make of makesList) {
            if (searchTerms.makeModelTree[make]) {
                // esFilters.should.push({
                //     term: {
                //         make: searchTerms.makeModelTree[make].make,
                //     },
                // });
                const modelsList = Object.keys(
                    inputFilters.makeModel[make].models
                );
                if (modelsList.length > 0) {
                    for (const model of modelsList) {
                        if (searchTerms.modelsTree[model]) {
                            esFilters.should.push({
                                term: {
                                    model: searchTerms.modelsTree[model].model,
                                },
                            });
                        }
                    }
                }
            }
        }
    }

    const transmissionsList = Object.keys(inputFilters.transmission);
    //Handle transmission
    if (transmissionsList.length > 0) {
        const transmissionFilterContainer = {
            bool: {
                should: [],
                minimum_should_match: 1,
            },
        };

        for (const transmission of transmissionsList) {
            transmissionFilterContainer.bool.should.push({
                term: {
                    transmission: transmission,
                },
            });
        }
        if (transmissionFilterContainer.bool.should.length > 0) {
            esFilters.must.push(transmissionFilterContainer);
        }
    }

    //Handle year
    if (inputFilters.year && inputFilters.year.min) {
        if (inputFilters.year.max) {
            esFilters.must.push({
                range: {
                    year: {
                        gte: Number(inputFilters.year.min),
                        lte: Number(inputFilters.year.max),
                    },
                },
            });
        } else {
            esFilters.must.push({
                range: {
                    year: {
                        gte: Number(inputFilters.year.min),
                    },
                },
            });
        }
    }

    //Handle kilometers
    if (inputFilters.kilometers) {
        esFilters.must.push({
            range: {
                kilometers: {
                    lte: Number(inputFilters.kilometers),
                },
            },
        });
    }

    //Handle price
    if (
        inputFilters.price &&
        (inputFilters.price.min || inputFilters.price.max)
    ) {
        let min = searchTerms.priceTerms.min;
        let max = searchTerms.priceTerms.max;
        if (Number(inputFilters.price.min) > min) {
            min = Math.max(Number(inputFilters.price.min), min);
        }
        if (Number(inputFilters.price.max) < max) {
            max = Math.min(Number(inputFilters.price.max), max);
        }
        if (
            min != searchTerms.priceTerms.min ||
            max !== searchTerms.priceTerms.max
        ) {
            esFilters.must.push({
                bool: {
                    should: [
                        {
                            bool: {
                                must: [
                                    {
                                        range: {
                                            sylndrSellingPrice: {
                                                gte: min,
                                                lte: max,
                                            },
                                        },
                                    },
                                    {
                                        term: {
                                            discountPrice: 0,
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            range: {
                                discountPrice: {
                                    gte: min,
                                    lte: max,
                                },
                            },
                        },
                    ],
                    minimum_should_match: 1,
                },
            });
        }
    }
    //Handle price
    if (inputFilters.emi && (inputFilters.emi.min || inputFilters.emi.max)) {
        let min = searchTerms.emiTerms.min;
        let max = searchTerms.emiTerms.max;
        if (Number(inputFilters.emi.min) > min) {
            min = Math.max(Number(inputFilters.emi.min), min);
        }
        if (Number(inputFilters.emi.max) < max) {
            max = Math.min(Number(inputFilters.emi.max), max);
        }
        if (
            min != searchTerms.emiTerms.min ||
            max !== searchTerms.emiTerms.max
        ) {
            esFilters.must.push({
                range: {
                    installmentPrice: {
                        gte: min,
                        lte: max,
                    },
                },
            });
        }
    }

    //Handle bodyStyle
    const bodyStyleList = Object.keys(inputFilters.bodystyle);
    if (bodyStyleList.length > 0) {
        const bodyStyleFilterContainer = {
            bool: {
                should: [],
                minimum_should_match: 1,
            },
        };

        for (const bodyStyle of bodyStyleList) {
            if (searchTerms.bodyStyleTerms.bodyStyles[bodyStyle]) {
                bodyStyleFilterContainer.bool.should.push({
                    term: {
                        bodyStyle:
                            searchTerms.bodyStyleTerms.bodyStyles[bodyStyle],
                    },
                });
            }
        }

        if (bodyStyleFilterContainer.bool.should.length > 0) {
            esFilters.must.push(bodyStyleFilterContainer);
        }
    }

    //Handle Status
    if (inputFilters.status) {
        if (inputFilters.status === CarStatus.BEING_SOLD) {
            esFilters.must.push({
                term: {
                    isPurchaseInProgress: true,
                },
            });
        } else if (inputFilters.status === CarStatus.RESERVED) {
            esFilters.must.push({
                term: {
                    status: CarDBStatus.RESERVED,
                },
            });
        } else {
            esFilters.must.push({
                term: {
                    status: CarDBStatus.UNRESERVED,
                },
            });
        }
    }
    if (inputFilters.discounted) {
        esFilters.must.push({
            range: {
                discountPrice: {
                    gt: 0,
                },
            },
        });
    }

    return esFilters;
};
const deepEqual = (obj1: IClientRetailFilters, obj2: IClientRetailFilters) => {
    // Base case: If both objects are identical, return true.
    if (obj1 === obj2) {
        return true;
    }
    // Check if both objects are objects and not null.
    if (
        typeof obj1 !== 'object' ||
        typeof obj2 !== 'object' ||
        obj1 === null ||
        obj2 === null
    ) {
        return false;
    }
    // Get the keys of both objects.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same.
    if (keys1.length !== keys2.length) {
        return false;
    }
    // Iterate through the keys and compare their values recursively.
    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    // If all checks pass, the objects are deep equal.
    return true;
};
export const isDefaultFilters = (filters: IClientRetailFilters): boolean => {
    const defaults = getDefaultIClientRetailFilters();
    return isIClientRetailFiltersEqual(defaults, filters);
};
export const isIClientRetailFiltersEqual = (
    f1: IClientRetailFilters,
    f2: IClientRetailFilters
): boolean => {
    return deepEqual(f1, f2);
};
export const cloneClientRetailFilters = (
    obj: IClientRetailFilters
): IClientRetailFilters => {
    return structuredClone(obj);
    // return JSON.parse(JSON.stringify(obj));
};

export const buildTrackingObject = (
    filters: IClientRetailFilters,
    resultsCount: number
) => {
    return {
        'Results Found': resultsCount,
        Make: Object.keys(filters.makeModel).join(','),
        Model: Object.keys(filters.makeModel)
            .map(make => Object.keys(filters.makeModel[make].models))
            .flat()
            .join(','),
        'Year From':
            filters.year.min === defaultYearFilter.min
                ? null
                : filters.year.min,
        'Year To':
            filters.year.max === defaultYearFilter.max
                ? null
                : filters.year.max,
        'Min Price':
            filters.price.min === defaultPriceFilter.min
                ? null
                : filters.price.min,
        'Max Price':
            filters.price.max === defaultPriceFilter.max
                ? null
                : filters.price.max,
        'Min Emi':
            filters.emi.min === defaultEmiFilter.min ? null : filters.price.min,
        'Max Emi':
            filters.emi.max === defaultEmiFilter.max ? null : filters.price.max,
        Kilometrage: filters.kilometers,
        Transmission: Object.keys(filters.transmission).join(','),
        'Body Type': Object.keys(filters.bodystyle).join(','),
        status: filters.status,
        discounted: filters.discounted,
    };
};
