'use client';
import { useAnalytics } from '@/integrations';
import {
    cloneClientRetailFilters,
    isDefaultFilters,
    slugify,
} from '@/utils/filterUtil';
import {
    defaultEmiFilter,
    defaultPriceFilter,
    defaultYearFilter,
    getDefaultIClientRetailFilters,
    IClientRetailFilters,
    ISearchTerms,
} from '@/types';
import { useEffect, useRef, useState } from 'react';

const useFilter = (
    searchTerms: ISearchTerms,
    filters: IClientRetailFilters,
    setFilters: (arg: IClientRetailFilters) => void
) => {
    const { trackEvent } = useAnalytics();

    const [localFilters, setLocalFilters] = useState<IClientRetailFilters>(
        cloneClientRetailFilters(filters)
    );
    const [filterObjectNotEmpty, setFilterObjectNotEmpty] = useState(
        !isDefaultFilters(localFilters)
    );
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (!isInitialRender.current) {
            const clonedFilters = cloneClientRetailFilters(localFilters);
            setFilterObjectNotEmpty(!isDefaultFilters(clonedFilters));
            setFilters(clonedFilters);
        }
        isInitialRender.current = false;
    }, [localFilters]);

    const makeModelValueChanged = (vehicle: {
        make: string;
        model: string;
    }) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);
        let { make = '', model = '' } = vehicle;
        const makeOnly = model === '';
        make = slugify(make);
        model = slugify(model);

        if (make && makeOnly) {
            if (updatedFilters.makeModel[make]) {
                delete updatedFilters.makeModel[make];
            } else {
                if (searchTerms.makeModelTree[make]) {
                    updatedFilters.makeModel[make] = {
                        make: make,
                        models: { ...searchTerms.makeModelTree[make].models },
                    };
                }
            }
        }

        //user is clicking on model
        if (make && model) {
            // checking if make and model exists ?
            if (
                searchTerms.makeModelTree[make] &&
                searchTerms.makeModelTree[make].models[model]
            ) {
                // check if parent make is already selected.
                if (updatedFilters.makeModel[make]) {
                    // check if the clicked model is already selected so you should delete.
                    if (updatedFilters.makeModel[make].models[model]) {
                        delete updatedFilters.makeModel[make].models[model];
                        // check if it were the last selected model of the parent make so drop the make also.
                        if (
                            Object.keys(updatedFilters.makeModel[make].models)
                                .length === 0
                        ) {
                            delete updatedFilters.makeModel[make];
                        }
                    } else {
                        // the clicked model is not selected so add it to filters.
                        updatedFilters.makeModel[make].models[model] =
                            searchTerms.makeModelTree[make].models[model];
                    }
                } else {
                    // the parent make is not selected so add the make and the clicked model to filters.
                    updatedFilters.makeModel[make] = {
                        make: searchTerms.makeModelTree[make].make,
                        models: {
                            [model]:
                                searchTerms.makeModelTree[make].models[model],
                        },
                    };
                }
            }
        }
        // console.log('{ ...updatedFilters }', { ...updatedFilters });
        setLocalFilters(updatedFilters);
    };

    const kilometersValueChanged = (text: string) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        if (
            updatedFilters.kilometers &&
            updatedFilters.kilometers === Number(text)
        ) {
            delete updatedFilters.kilometers;
        } else {
            updatedFilters.kilometers = Number(text);
        }
        setLocalFilters(updatedFilters);
    };

    const availableValueChanged = (text: string) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        if (updatedFilters.status && updatedFilters.status === text) {
            delete updatedFilters.status;
        } else {
            updatedFilters.status = text;
        }

        setLocalFilters(updatedFilters);
    };
    const bodyTypeValueChanged = (text: string) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        const slug = slugify(text);
        if (!searchTerms.bodyStyleTerms.bodyStyles[slug]) {
            return;
        }

        if (updatedFilters.bodystyle[slug]) {
            delete updatedFilters.bodystyle[slug];
        } else {
            updatedFilters.bodystyle[slug] =
                searchTerms.bodyStyleTerms.bodyStyles[slug];
        }

        setLocalFilters(updatedFilters);
    };

    const transmissionValueChanged = (text: string) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        const slug = slugify(text);
        if (updatedFilters.transmission[slug]) {
            delete updatedFilters.transmission[slug];
        } else {
            updatedFilters.transmission[slug] = slug;
        }
        setLocalFilters(updatedFilters);
    };

    const priceRangeChanged = (value: number[]) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        updatedFilters.price = {
            min: Math.max(Number(value[0]), searchTerms.priceTerms.min),
            max: Math.min(Number(value[1]), searchTerms.priceTerms.max),
        };

        //to remove the chip if user manual filter for the full range.
        if (
            updatedFilters.price.min === searchTerms.priceTerms.min &&
            updatedFilters.price.max === searchTerms.priceTerms.max
        ) {
            updatedFilters.price.min = defaultPriceFilter.min;
            updatedFilters.price.max = defaultPriceFilter.max;
        }
        setLocalFilters(updatedFilters);
    };
    const emiRangeChanged = (value: number[]) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        updatedFilters.emi = {
            min: Math.max(Number(value[0]), searchTerms.emiTerms.min),
            max: Math.min(Number(value[1]), searchTerms.emiTerms.max),
        };
        if (
            updatedFilters.emi.min === searchTerms.emiTerms.min &&
            updatedFilters.emi.max === searchTerms.emiTerms.max
        ) {
            updatedFilters.emi.min = defaultEmiFilter.min;
            updatedFilters.emi.max = defaultEmiFilter.max;
        }
        setLocalFilters(updatedFilters);
    };

    const yearRangeChanged = (value: number[]) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        updatedFilters.year.min =
            value?.[0] && Number(value[0])
                ? Number(value[0])
                : defaultYearFilter.min;

        updatedFilters.year.max =
            value?.[0] &&
            Number(value[1]) &&
            updatedFilters.year.min !== defaultYearFilter.min
                ? Number(value[1])
                : defaultYearFilter.max;

        setLocalFilters(updatedFilters);
    };

    const kilometersTagClicked = () => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        delete updatedFilters.kilometers;
        setLocalFilters(updatedFilters);
    };
    const availableTagClicked = () => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        delete updatedFilters.status;
        setLocalFilters(updatedFilters);
    };
    const transmissionTagClicked = (text: string | number) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        const slug = slugify(String(text));
        if (updatedFilters.transmission) {
            delete updatedFilters.transmission[slug];
            setLocalFilters(updatedFilters);
        }
    };
    const bodyStyleTagClicked = (text: string | number) => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        const slug = slugify(String(text));
        if (updatedFilters.bodystyle) {
            delete updatedFilters.bodystyle[slug];
        }
        setLocalFilters(updatedFilters);
    };
    const yearTagClicked = () => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        updatedFilters.year = {
            min: defaultYearFilter.min,
            max: defaultYearFilter.max,
        };
        setLocalFilters(updatedFilters);
    };

    const priceTagClicked = () => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        updatedFilters.price = {
            min: defaultPriceFilter.min,
            max: defaultPriceFilter.max,
        };
        setLocalFilters(updatedFilters);
    };

    const emiTagClicked = () => {
        const updatedFilters = cloneClientRetailFilters(localFilters);

        updatedFilters.emi = {
            min: defaultEmiFilter.min,
            max: defaultEmiFilter.max,
        };

        setLocalFilters(updatedFilters);
    };
    const makeTagClicked = (text: string | number) => {
        makeModelValueChanged({ make: slugify(String(text)), model: '' });
    };
    const modelTagClicked = (vehicle: { make: string; model: string }) => {
        makeModelValueChanged(vehicle);
    };
    const discountedValueToggle = () => {
        const updatedFilters = cloneClientRetailFilters(localFilters);
        updatedFilters.discounted = !updatedFilters.discounted;
        setLocalFilters(updatedFilters);
    };
    const discountedTagClicked = () => {
        const updatedFilters = cloneClientRetailFilters(localFilters);
        updatedFilters.discounted = false;
        setLocalFilters(updatedFilters);
    };

    const clearFilters = () => {
        const emptyState = cloneClientRetailFilters(
            getDefaultIClientRetailFilters()
        );
        trackEvent('Action: Clear Filter');
        setLocalFilters(emptyState);
    };

    return {
        makeModelValueChanged,
        kilometersValueChanged,
        bodyTypeValueChanged,
        transmissionValueChanged,
        priceRangeChanged,
        emiRangeChanged,
        yearRangeChanged,
        availableValueChanged,
        availableTagClicked,
        kilometersTagClicked,
        transmissionTagClicked,
        bodyTypeTagClicked: bodyStyleTagClicked,
        yearTagClicked,
        priceTagClicked,
        emiTagClicked,
        makeTagClicked,
        modelTagClicked,
        clearFilters,
        localFilters,
        setLocalFilters,
        filterObjectNotEmpty,
        discountedTagClicked,
        discountedValueToggle,
    };
};

export default useFilter;
