// export const seeShowroomBookingSteps = [
//   {
//     id: 0,
//     title: 'Appointment',
//     subtitle: 'booking details',
//     subtitleWhenDone: 'on appointment',
//     completed: false,
//     previousBtnText: 'back',
//     nextBtnText: 'Continue',
//   },
//   {
//     id: 1,
//     title: 'verification',
//     subtitle: 'verify your mobile number',
//     subtitleWhenDone: 'mobile verified',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'get OTP',
//   },
// ];

// export const seeHomeTestDirveBookingSteps = [
//   {
//     id: 2,
//     title: 'test drive',
//     subtitle: '',
//     subtitleWhenDone: 'test drive',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'Continue',
//   },
//   {
//     id: 3,
//     title: 'location step title',
//     subtitle: '',
//     subtitleWhenDone: 'location step title',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'Continue',
//   },
//   {
//     id: 0,
//     title: 'booking details',
//     subtitle: '',
//     subtitleWhenDone: 'Book Details',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'Continue',
//   },
//   {
//     id: 1,
//     title: 'verification',
//     subtitle: '',
//     subtitleWhenDone: 'verification',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'get OTP',
//   },
//   {
//     id: 4,
//     title: 'payment Step',
//     subtitle: '',
//     subtitleWhenDone: 'Payment',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'Continue',
//   },
// ];

// export const reserveShowRoomBookingSteps = [
//   {
//     id: 2,
//     title: 'test drive',
//     subtitle: '',
//     subtitleWhenDone: 'test drive',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'Continue',
//   },
//   {
//     id: 0,
//     title: 'booking details',
//     subtitle: '',
//     subtitleWhenDone: 'Book Details',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'Continue',
//   },
//   {
//     id: 1,
//     title: 'verification',
//     subtitle: '',
//     subtitleWhenDone: 'verification',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'get OTP',
//   },
//   {
//     id: 4,
//     title: 'payment Step',
//     subtitle: '',
//     subtitleWhenDone: 'Payment',
//     completed: false,
//     previousBtnText: 'Back',
//     nextBtnText: 'Continue',
//   },
// ];

//////////////////////////////////

export const seeOnlyShowroomBookingSteps = [
    {
        id: 0,
        title: 'Appointment',
        subtitle: 'booking details',
        subtitleWhenDone: 'on appointment',
        completed: false,
        previousBtnText: 'back',
        nextBtnText: 'Continue',
    },
    {
        id: 1,
        title: 'verification',
        subtitle: 'verify your mobile number',
        subtitleWhenDone: 'mobile verified',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'get OTP',
    },
];
export const reserveOnlyShowroomBookingSteps = [
    {
        id: 0,
        title: 'booking details',
        subtitle: '',
        subtitleWhenDone: 'Book Details',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 1,
        title: 'verification',
        subtitle: '',
        subtitleWhenDone: 'verification',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'get OTP',
    },
    {
        id: 4,
        title: 'payment Step',
        subtitle: '',
        subtitleWhenDone: 'Payment',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
];
export const generalBookingSteps = [
    {
        id: 2,
        title: 'test drive',
        subtitle: '',
        subtitleWhenDone: 'test drive',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 0,
        title: 'booking details',
        subtitle: '',
        subtitleWhenDone: 'Book Details',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 1,
        title: 'verification',
        subtitle: '',
        subtitleWhenDone: 'verification',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'get OTP',
    },
    {
        id: 4,
        title: 'payment Step',
        subtitle: '',
        subtitleWhenDone: 'Payment',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
];
export const generalOnlyHomeBookingSteps = [
    {
        id: 3,
        title: 'location step title',
        subtitle: '',
        subtitleWhenDone: 'location step title',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 0,
        title: 'booking details',
        subtitle: '',
        subtitleWhenDone: 'Book Details',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 1,
        title: 'verification',
        subtitle: '',
        subtitleWhenDone: 'verification',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'get OTP',
    },
    {
        id: 4,
        title: 'payment Step',
        subtitle: '',
        subtitleWhenDone: 'Payment',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
];

export const seeShowroomBookingSteps = [
    {
        id: 2,
        title: 'test drive',
        subtitle: '',
        subtitleWhenDone: 'test drive',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 0,
        title: 'Appointment',
        subtitle: 'booking details',
        subtitleWhenDone: 'on appointment',
        completed: false,
        previousBtnText: 'back',
        nextBtnText: 'Continue',
    },
    {
        id: 1,
        title: 'verification',
        subtitle: 'verify your mobile number',
        subtitleWhenDone: 'mobile verified',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'get OTP',
    },
];

export const generalHomeTestDirveBookingSteps = [
    {
        id: 2,
        title: 'test drive',
        subtitle: '',
        subtitleWhenDone: 'test drive',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 3,
        title: 'location step title',
        subtitle: '',
        subtitleWhenDone: 'location step title',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 0,
        title: 'booking details',
        subtitle: '',
        subtitleWhenDone: 'Book Details',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
    {
        id: 1,
        title: 'verification',
        subtitle: '',
        subtitleWhenDone: 'verification',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'get OTP',
    },
    {
        id: 4,
        title: 'payment Step',
        subtitle: '',
        subtitleWhenDone: 'Payment',
        completed: false,
        previousBtnText: 'Back',
        nextBtnText: 'Continue',
    },
];
