import { fetchWithInterceptorInstance } from '@/classes/FetchWithInterceptor';

export const baseQueryWrapper =
    ({ baseUrl }) =>
    async ({ url, method, body = null }) => {
        const constructedUrl = `${baseUrl}${url}`;
        try {
            const fetchInput: any = { url: constructedUrl, method };
            if (body) {
                fetchInput.body = body;
            }
            const response = await fetchWithInterceptorInstance.use(fetchInput);
            // { data : responseBody } is the need format for rtk baseQuery success
            return { data: response };
        } catch (error) {
            // { error: { data: error } } is the need format for rtk baseQuery failure
            return { error: { data: error } };
        }
    };
