import axios, { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import {
    Area,
    ICity,
    OtpResponse,
    Region,
    VerifyOtpRequest,
    VerifyOtpRequestPath2,
} from '@/types';

export const useGetRegions = () => {
    return useQuery<Region[], AxiosError>('regions', async () => {
        const response = await axios.get<{ data: ICity[] }>('/areas');

        const regions = response.data.data;

        // regions.sort(function (region1, region2) {
        //   return region1.bigArea === 'Top Areas' ? -1 : region2.bigArea === 'Top Areas' ? 1 : 0;
        // });
        const formatttedRegions: Region[] = [];
        for (const city of regions) {
            const formattedRegion: Region = {
                id: city.cityId,
                bigArea: city.cityEnName,
                arBigArea: city.cityArName,
                smallArea: city.areas.map(area => {
                    return {
                        id: area.areaId,
                        polygon: area.coordinates.map(coord => {
                            return {
                                lng: coord.longitude,
                                lat: coord.latitude,
                            };
                        }),
                        name: area.areaEnName,
                        arName: area.areaArName,
                        serviceable: area.serviceable,
                        warehouse: area.warehouse,
                    } as Area;
                }),
            };
            formatttedRegions.push(formattedRegion);
        }
        return formatttedRegions;
    });
};

// export const useSendOtp = () => {
//   const { i18n } = useTranslation();

//   return useMutation(async ({ phoneNumber }: { phoneNumber: string }) => {
//     const response = await axios.post<OtpResponse>('/sendMobileOTP', {
//       phoneNumber,
//       language: i18n.language,
//     });

//     return response.data;
//   });
// };

export const useVerifyOtp = () => {
    return useMutation(async (verifyOtpRequest: VerifyOtpRequest) => {
        const response = await axios.post<OtpResponse>(
            '/v2/hTCmGnuJLjUaISKsjcSIMEFWAj', // verifyOtpURL
            verifyOtpRequest
        );

        return response;
    });
};

export const useVerifyNoneShopingListOtp = () => {
    return useMutation(async (verifyOtpRequestPath2: VerifyOtpRequestPath2) => {
        const response = await axios.post<OtpResponse>(
            '/v2/verifyFutureLeads',
            verifyOtpRequestPath2
        );

        return response.data;
    });
};
